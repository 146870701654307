import type { IImage } from 'old-store/types/gallery';
import moment from 'moment';

interface IUrlSizes {
  url: string;
  url_XS: string;
  url_S: string;
  url_M: string;
}

interface ICdnImageUrlOptions {
  image: Partial<IImage>;
  size?: string;
  wildcardSize?: boolean;
  noUserDir?: boolean;
  noImgsFolder?: boolean;
  noCdnUrl?: boolean;
}

const getUrlSizing = (url: string): IUrlSizes => ({
  url: url.replace('$size$', 'L'),
  url_XS: url.replace('$size$', 'XS'),
  url_S: url.replace('$size$', 'S'),
  url_M: url.replace('$size$', 'M')
});

export const getCdnImageUrl = ({
  image,
  size,
  wildcardSize = false,
  noUserDir = false,
  noImgsFolder = false,
  noCdnUrl = false
}: ICdnImageUrlOptions): string => {
  try {
    if (image.isPublic) {
      return `${window.CDN_URL}/public/${image.identifier}.${image.extension}`;
    }

    let key = '';

    // @ts-expect-error user should no longer have an id
    if (!noUserDir) key += `${image._user?._id || image._user}/`;
    if (!noImgsFolder) key += `${image.imgsFolder}/`;

    if (
      image?.extension?.toLowerCase() === 'gif' &&
      moment(image.uploadedAt).isAfter(moment('2022-11-16 13:40'))
    ) {
      key += `${image.identifier}.${image.extension}`;
    } else {
      // @ts-expect-error the image should no longer have a watermarkIdentifier
      key += `${image.watermarkIdentifier ?? image.identifier}/`;

      if (image._watermark) {
        key += 'wm/';
      }

      key += wildcardSize ? '$size$' : size || 'L';
      key += `.${image.extension}`;
    }

    if (noCdnUrl) return `${key}`;

    return `${window.CDN_URL}/${key}`;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const getImageUrls = (image: Partial<IImage>, signedWildCardUrl?: string): IUrlSizes => {
  let url = '';

  if (image.isPublic) {
    url = getCdnImageUrl({ image });
  } else {
    if (signedWildCardUrl) {
      url = signedWildCardUrl.replace(
        '*',
        // @ts-ignore
        getCdnImageUrl({
          image,
          wildcardSize: true,
          noUserDir: true,
          noImgsFolder: true,
          noCdnUrl: true
        })
      );
    } else {
      url = getCdnImageUrl({
        image,
        wildcardSize: true
      });
    }
  }

  return getUrlSizing(url);
};
