import { useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import { createRootReducer, store } from 'old-store/store';
import * as shopReducers from 'modules/ScrShop/store/reducers';
import { reducers as rtkShopReducers } from 'modules/ScrShopNew/store';

export const useShopManager = () => {
  const { collectionCreator = {} } = useAppSelector((state) => state.collection);
  const { featureFlags } = collectionCreator || {};
  const { newShop } = featureFlags || {};
  const { pathname } = window.location;

  const isOldShopLink = pathname.endsWith('/shop') || pathname.includes('/shop/');
  const isNewShopLink = pathname.includes('/shop-new');

  const shouldUseNewShop = newShop && isOldShopLink;
  const shouldUseOldShop = !newShop && isNewShopLink;
  const needsShopSwitch = shouldUseNewShop || shouldUseOldShop;
  const shouldRedirectToPrevLink = pathname.includes('/products/') || pathname.includes('/basket');

  const clearStorage = () => {
    localStorage.removeItem(`shipping_${window.SITE_ID}`);
    localStorage.removeItem(`checkout_${window.SITE_ID}`);
    localStorage.removeItem(`shop_${window.SITE_ID}`);
  };

  const redirectToShop = (target: string) => {
    clearStorage();
    window.location.href = `/${window.SITE_ID}/${target}`;
  };

  useEffect(() => {
    const redirectToShopWithPreservedLinks = () => {
      const url = new URL(window.location.href);

      if (shouldUseNewShop) {
        url.pathname = url.pathname.replace('/shop', '/shop-new');
      } else if (shouldUseOldShop) {
        url.pathname = url.pathname.replace('/shop-new', '/shop');
      }

      window.location.replace(url.toString());
    };

    const handleShopRedirects = () => {
      if (shouldRedirectToPrevLink && needsShopSwitch) {
        redirectToShopWithPreservedLinks();

        return;
      }

      if (shouldUseNewShop) {
        redirectToShop('shop-new');
      } else if (shouldUseOldShop) {
        redirectToShop('shop');
      }
    };

    const handleShopReducers = () => {
      if (newShop) {
        store.replaceReducer(createRootReducer(rtkShopReducers));
      } else {
        store.replaceReducer(createRootReducer(shopReducers));
      }
    };

    handleShopRedirects();
    handleShopReducers();
  }, [newShop, pathname]);
};
