import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTranslationKey } from 'helpers/texting';
import { Api } from 'old-store/utils';
import { setCollection } from 'store/slices/collection';
import { fetchSelectionImages, setImages } from 'store/slices/images';
import { saveSelection } from 'store/slices/selection';
import { showGlobalError } from '../error';
import { getProductByID, getProductBySlug } from '../products/selectors';
import { cloneDeep, find, get, keyBy, omit } from 'lodash';
import {
  getHorizontalImages,
  getSelectedImagesIDsSelector,
  getShopCroppedImagesSelector,
  getShopOptionSelector,
  getVerticalImages
} from './selectors';
import { RootState } from 'old-store/store';
import {
  IAddImageAction,
  IAddImagesAction,
  ICreateAccountWithPasswordParams,
  ILoginWithPasswordParams,
  ISetShopEditMode
} from './types';
import {
  fetchDigitalPricingForUpsellingSuccess,
  fetchGlobalShopSettingsForCollectionActionSuccess,
  fetchLaboratoryActionSuccess,
  fetchPackagesForUpsellingSuccess,
  fetchProductBestsellerListSuccess,
  fetchProductRecListSuccess,
  setCropImagesAction,
  setGroupedByGalleriesImages,
  setGroupedImages,
  setSelectedImagesSuccessAction,
  startFetchingPackagesForUpselling,
  stopFetchingPackagesForUpselling,
  updateShopDataAction
} from '.';
import { getCollectionById, trackEventAction } from 'old-store/actions';
import {
  deleteCroppedImageSuccessAction,
  resetLoginWithPasswordFlow,
  resetSignupWithPasswordFlow,
  setCreateAccountWithPasswordFlowStatus,
  setEndCustomerEmailWasNotFound,
  setEndCustomerHasPassword,
  setForgotPasswordFlowStatus,
  setLoginFlowStatus,
  toggleEnterPasswordModalAction,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShopNew/store/slices/shop';
import {
  claimCheckoutAction,
  fetchCheckoutAction
} from 'modules/ScrShopNew/store/slices/checkout/actions';
import { globalError } from 'modules/ScrShopNew/store/slices/error';
import { getGalleries } from 'modules/ScrShopNew/store/slices/galleries/selectors';
import { getImagesByGalleries, getShuffledImages } from 'modules/ScrShopNew/store/utils/helpers';
import { getCheckoutProductByID } from '../checkout/selectors';
import ApiErrors from 'old-store/utils/API/APIErrors';
import { getEndCustomerData } from 'old-store/selectors/endCustomer';
import { setCookie } from 'helpers/cookie';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { fetchMessage } from 'store/slices/messages/actions';
import TagManager from 'react-gtm-module';
import db from 'database';

const getIsAdding = (max: number, selected: string[], imageID: string) =>
  max === 1 && selected.indexOf(imageID) === -1;
const getIsRemoveImage = (max: number, selected: string[], imageID: string) =>
  selected.indexOf(imageID) > -1 && max !== 1;

export const updateSelection = createAsyncThunk(
  'selection/update',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { selections } = await Api.Auth.updateEndCustomer();
      dispatch(saveSelection({ selections, allUserSelections: [] }));
      dispatch(fetchSelectionImages(selections));
      dispatch({ type: 'fetch_selections', payload: selections });
      dispatch({ type: 'set_selection_fetch_status', payload: 'success' });

      const res = await Api.Site.get();
      dispatch(setCollection(res.result));

      return selections;
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response?.data);
    }
  }
);

export const setSelectedImage = createAsyncThunk(
  'image/setSelected',
  async (
    { imageID, productSlug, groupSlug }: IAddImageAction,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const selected = cloneDeep(getSelectedImagesIDsSelector(state));
      const croppedImages = getShopCroppedImagesSelector(state);
      const product =
        groupSlug === 'digital'
          ? getProductByID(state)(productSlug)
          : getProductBySlug(state)(productSlug);

      // @ts-ignore
      const { maxImageSelection } = product;
      const isRemove = getIsRemoveImage(maxImageSelection, selected, imageID);

      if (!isRemove && maxImageSelection === selected.length && maxImageSelection !== 1) {
        dispatch(
          showGlobalError({
            title: getTranslationKey('shop.imagesSelectionModal.title'),
            text: getTranslationKey('shop.imagesSelectionModal.toManyImagesText'),
            confirmText: getTranslationKey('shop.imagesSelectionModal.button').toUpperCase()
          })
        );

        return rejectWithValue('Maximum selection limit reached');
      }

      let IDs = selected;
      if (getIsRemoveImage(maxImageSelection, selected, imageID)) {
        IDs = selected.filter((id) => id !== imageID);
        // @ts-ignore
        dispatch(setCropImagesAction(omit(croppedImages, imageID)));
      } else if (getIsAdding(maxImageSelection, selected, imageID)) {
        IDs = [imageID];
      } else if (!maxImageSelection || maxImageSelection > 1) {
        IDs.push(imageID);
      }

      dispatch(setSelectedImagesSuccessAction([...IDs]));
      dispatch(
        trackEventAction({
          name: 'images-selected',
          payload: {
            images_ids: [imageID],
            images_count: 1,
            product_group_slug: groupSlug,
            product_slug: productSlug
          }
        })
      );
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.message);
    }
  }
);

export const setSelectedImagesAction = createAsyncThunk(
  'images/setSelected',
  async (payload: IAddImagesAction, { getState, dispatch }) => {
    const state = getState() as RootState;
    const selectedItem = getSelectedImagesIDsSelector(state);
    const selected = [...selectedItem]; // Cloning for immutability
    const productDetails =
      payload.groupSlug === 'digital'
        ? getProductByID(state)(payload.productSlug)
        : getProductBySlug(state)(payload.productSlug);

    // @ts-ignore
    const { maxImageSelection } = productDetails;

    const imagesThatNotSelected = payload.imageIDs.filter((imageId) => !selected.includes(imageId));
    const allImagesSelected = payload.imageIDs.every((imageId) => selected.includes(imageId));

    if (imagesThatNotSelected.length > 0) {
      if (maxImageSelection === selected.length && maxImageSelection !== 1) {
        dispatch(
          showGlobalError({
            title: getTranslationKey('shop.imagesSelectionModal.title'),
            text: getTranslationKey('shop.imagesSelectionModal.tooManyImagesText'),
            confirmText: getTranslationKey('shop.imagesSelectionModal.button').toUpperCase()
          })
        );

        return;
      }

      const imagesToAdd =
        typeof maxImageSelection === 'number'
          ? imagesThatNotSelected.slice(0, maxImageSelection - selected.length)
          : imagesThatNotSelected;

      selected.push(...imagesToAdd);
    }

    // Removing deselected images
    if (allImagesSelected) {
      payload.imageIDs.forEach((imageId) => {
        const index = selected.indexOf(imageId);

        if (index > -1) {
          selected.splice(index, 1);
        }
      });
    }

    dispatch(setSelectedImagesSuccessAction([...selected]));
    dispatch(
      trackEventAction({
        name: 'images-selected',
        payload: {
          images_ids: payload.imageIDs,
          images_count: payload.imageIDs.length,
          product_group_slug: payload.groupSlug,
          product_slug: payload.productSlug
        }
      })
    );
  }
);

export const deleteCroppedImage = createAsyncThunk(
  'images/deleteCropped',
  async (payload: { _id: string; goBackCallback: () => void }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const selectedImagesIDs = getSelectedImagesIDsSelector(state);
    const croppedImages = getShopCroppedImagesSelector(state);

    const filteredSelected = selectedImagesIDs.filter((id) => id !== payload._id);
    const filteredCropped = omit(croppedImages, payload._id);

    dispatch(setSelectedImagesSuccessAction(filteredSelected));
    // @ts-ignore
    dispatch(deleteCroppedImageSuccessAction(filteredCropped));

    if (!filteredSelected.length) {
      payload?.goBackCallback();
    }
  }
);

export const setShopEditMode = createAsyncThunk(
  'shop/setEditMode',
  async (payload: ISetShopEditMode, { getState, dispatch }) => {
    const state = getState() as RootState;
    const checkoutProduct = getCheckoutProductByID(state)(payload.cartSelectedProductID);
    const { options } = getProductByID(state)(checkoutProduct?._product) || {};
    const shopOptions = cloneDeep(getShopOptionSelector(state));
    const images = [...get(checkoutProduct, 'images', [])].sort((a, b) => a.order - b.order);
    const convertImages = images.map((image) => ({
      ...image.crop,
      quantity: image.quantity,
      _id: image._id,
      _image: `${checkoutProduct.slug}-${image._image}`
    }));

    checkoutProduct.selectedOptions.forEach((option) => {
      const productOption = find(options, { _id: option });
      if (productOption) {
        shopOptions[productOption.type] = option;
      }
    });

    dispatch(setSelectedImagesSuccessAction(images.map((image) => image._image)));
    dispatch(
      updateShopDataAction({
        options: shopOptions,
        croppedImages: keyBy(convertImages, '_image'),
        cartSelectedProductID: payload.cartSelectedProductID,
        selectedProductID: payload.productID,
        isEditMode: true
      })
    );
  }
);

export const fetchGlobalShopSettingsForCollection = createAsyncThunk(
  'shop/fetchGlobalShopSettingsForCollection',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Shop.getGlobalShopSettingsForCollection(window.SITE_ID);
      ApiErrors.checkOnApiError(response);
      dispatch(fetchGlobalShopSettingsForCollectionActionSuccess(response.result));
    } catch (err) {
      // @ts-ignore
      if (err.code !== 404) {
        console.error(err);
      }

      return rejectWithValue(err);
    }
  }
);

export const checkEndCustomerPassword = createAsyncThunk(
  'endCustomer/checkPassword',
  async (payload: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Auth.checkPassword(payload, window.SITE_ID);
      ApiErrors.checkOnApiError(response);
      dispatch(setEndCustomerHasPassword(response.result.endCustomerHasPassword));
      dispatch(toggleLoginWithPasswordModalAction());
      dispatch(toggleEnterPasswordModalAction());
    } catch (err) {
      // @ts-ignore
      if (err.code !== 404) {
        console.error(err);
      } else {
        dispatch(setEndCustomerEmailWasNotFound(true));
      }

      return rejectWithValue(err);
    }
  }
);

export const checkEndCustomerEmail = createAsyncThunk(
  'endCustomer/checkEmail',
  async (payload: string, { dispatch }) => {
    try {
      const response = await Api.Auth.checkEmail(payload, window.SITE_ID);
      ApiErrors.checkOnApiError(response);

      if (response.result.emailIsAlreadyTaken && !response.result.endCustomerHasPassword) {
        dispatch(
          setCreateAccountWithPasswordFlowStatus({
            showEmailModal: false
          })
        );
        dispatch(
          setLoginFlowStatus({
            email: payload,
            showEnterPasswordModal: true,
            endCustomerHasPassword: false
          })
        );
      } else {
        dispatch(
          setCreateAccountWithPasswordFlowStatus({
            showEmailModal:
              response.result.emailIsAlreadyTaken && response.result.endCustomerHasPassword,
            showPasswordModal: !response.result.emailIsAlreadyTaken,
            emailIsAlreadyTaken:
              response.result.emailIsAlreadyTaken && response.result.endCustomerHasPassword
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'endCustomer/resetPassword',
  async (payload: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Auth.resetPassword(payload, window.SITE_ID);
      ApiErrors.checkOnApiError(response);
      dispatch(
        setForgotPasswordFlowStatus({
          showSuccessMessage: true
        })
      );
    } catch (err) {
      // @ts-ignore
      if (err.code === 404) {
        dispatch(
          setForgotPasswordFlowStatus({
            emailDoesNotExists: true
          })
        );
      } else {
        console.error(err);
      }

      return rejectWithValue(err);
    }
  }
);

export const fetchProductRecList = createAsyncThunk(
  'shop/fetchProductRecList',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Shop.getRecommendations();
      ApiErrors.checkOnApiError(response);
      dispatch(fetchProductRecListSuccess(response.result));
    } catch (e) {
      console.error(e);

      return rejectWithValue(e);
    }
  }
);

export const fetchProductBestsellerList = createAsyncThunk(
  'shop/fetchProductBestsellerList',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Shop.getBestsellers();
      ApiErrors.checkOnApiError(response);
      dispatch(fetchProductBestsellerListSuccess(response.result));
    } catch (e) {
      console.error(e);

      return rejectWithValue(e);
    }
  }
);

export const loginCustomerWithPassword = createAsyncThunk(
  'endCustomer/loginWithPassword',
  async (payload: ILoginWithPasswordParams, { dispatch, getState }) => {
    const formValues = {
      collectionId: window.SITE_ID,
      ...payload
    };

    try {
      // Delete cache before login
      // @ts-ignore
      await db.sites.delete(window.SITE_ID).catch((e) => {
        if (e && e.inner) console.log('DataBaseError helper deleteCache inner:', e.inner, e);
        else console.log('DataBaseError helper deleteCache:', e);
      });

      dispatch(setLoginFlowStatus({ passwordIsInvalid: false }));

      const state = getState();
      const { _id, type } = getEndCustomerData(state);
      const response = await Api.Auth.login(formValues);
      ApiErrors.checkOnApiError(response);

      const { endCustomerToken, endCustomer } = response.result;
      // @ts-ignore
      const { signedWildCardUrl } = state.collection; // Assuming this exists, might need to adjust based on actual state structure

      setCookie('endCustomerToken', endCustomerToken, 30);
      dispatch({ type: 'fetch_endCustomer', payload: endCustomer });
      dispatch(
        trackEventAction({
          name: 'user-signed-in',
          payload: { endCustomer, flyUserId: type === 'fly' && _id }
        })
      );

      await dispatch(updateSelection());

      dispatch(resetLoginWithPasswordFlow());
      dispatch(fetchProductBestsellerList());
      dispatch(fetchProductRecList());
      dispatch(saveEndCustomer({ ...endCustomer, _id: endCustomer.id, authed: true }));
      dispatch(fetchMessage());
      dispatch(fetchCheckoutAction());

      localStorage.setItem(
        'endCustomerToken',
        // @ts-ignore
        JSON.stringify({ [state.collection._user]: endCustomerToken })
      );

      TagManager.dataLayer({
        dataLayer: { event: 'login' },
        dataLayerName: window.SHOP_DATA_LAYER_NAME
      });

      // Refetch images to synchronize user id
      const images = await Api.Images.getImages();
      dispatch(setImages({ images, signedWildCardUrl }));
      dispatch(getCollectionById(window.SITE_ID));
    } catch (e) {
      // @ts-ignore
      if (e.code === 403) {
        dispatch(setLoginFlowStatus({ passwordIsInvalid: true }));
      } else {
        dispatch(globalError(e as any));
      }
    }
  }
);

export const updateEndCustomer = createAsyncThunk(
  'endCustomer/update',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await Api.Auth.updateEndCustomer();
      dispatch({ type: 'fetch_endCustomer', payload: res });
    } catch (e) {
      console.error(e);

      return rejectWithValue(e);
    }
  }
);

export const createAccountWithPassword = createAsyncThunk(
  'endCustomer/createAccountWithPassword',
  async (payload: ICreateAccountWithPasswordParams, { getState, dispatch, rejectWithValue }) => {
    try {
      console.log('createAccountWithPassword');
      const state = getState();
      const { _id, type } = getEndCustomerData(state);
      const response = await Api.Auth.signup(payload);
      ApiErrors.checkOnApiError(response);
      const { endCustomerToken, endCustomer } = response.result;
      setCookie('endCustomerToken', endCustomerToken, 30);

      // 'updateEndCustomer' has to be written with 'createAsyncThunk' to make it work and return promise
      await dispatch(updateEndCustomer());
      dispatch(claimCheckoutAction());
      dispatch(resetSignupWithPasswordFlow());
      dispatch(
        trackEventAction({
          name: 'user-signed-up',
          payload: { endCustomer, flyUserId: type === 'fly' && _id }
        })
      );
      dispatch(fetchProductBestsellerList());
      dispatch(fetchProductRecList());
      dispatch(
        saveEndCustomer({
          ...endCustomer,
          _id: endCustomer.id,
          authed: true
        })
      );

      // @ts-ignore
      const { _user, signedWildCardUrl } = state.collection;
      localStorage.setItem('endCustomerToken', JSON.stringify({ [_user]: endCustomerToken }));

      // Refetch images to synchronize user id, assuming Api.Images.getImages exists and is correctly referenced
      const images = await Api.Images.getImages();
      dispatch(setImages({ images, signedWildCardUrl }));
    } catch (e) {
      console.error(e);

      return rejectWithValue(e);
    }
  }
);

export const fetchLaboratory = createAsyncThunk(
  'shop/fetchLaboratory',
  async (payload: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Shop.getLaboratory(payload);
      ApiErrors.checkOnApiError(response);
      dispatch(fetchLaboratoryActionSuccess(response.result));
    } catch (e) {
      console.error(e);

      return rejectWithValue(e);
    }
  }
);

export const fetchPackagesForUpselling = createAsyncThunk(
  'shop/fetchPackagesForUpselling',
  async (payload: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startFetchingPackagesForUpselling());
      const response = await Api.Shop.getDigitalPricingListPackages(payload);
      ApiErrors.checkOnApiError(response);

      dispatch(fetchPackagesForUpsellingSuccess(response.result));
      dispatch(stopFetchingPackagesForUpselling());
    } catch (e) {
      console.error(e);
      dispatch(stopFetchingPackagesForUpselling());

      return rejectWithValue(e);
    }
  }
);

export const fetchDigitalPricingForUpselling = createAsyncThunk(
  'shop/fetchDigitalPricingForUpselling',
  async (payload: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.Shop.getDigitalPricingList(payload);
      ApiErrors.checkOnApiError(response);
      dispatch(fetchDigitalPricingForUpsellingSuccess(response.result));
    } catch (e) {
      console.error(e);

      return rejectWithValue(e);
    }
  }
);

export const groupImagesBySizeType = createAsyncThunk(
  'images/setGroupedImages',
  (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const galleries = getGalleries(state);
    const horizontal = getHorizontalImages(state) as any[];
    const vertical = getVerticalImages(state) as any[];

    const horizontalImages = getShuffledImages(horizontal);
    const verticalImages = getShuffledImages(vertical);
    const fallbackImages = getShuffledImages([...horizontal, ...vertical]);

    dispatch(
      setGroupedImages({
        horizontal: horizontalImages,
        vertical: verticalImages,
        fallback: fallbackImages
      })
    );

    dispatch(
      setGroupedByGalleriesImages({
        horizontal: getImagesByGalleries(horizontalImages, galleries),
        vertical: getImagesByGalleries(verticalImages, galleries),
        fallback: getImagesByGalleries(fallbackImages, galleries)
      })
    );
  }
);
