export const convertToGroupedSlug = (slug: string) => {
  const slugArr = slug.split('-');

  if (slug.includes('photobook')) {
    return slugArr.slice(0, 1).join('-');
  }

  const separatorLength = slug.includes('-mug-') ? 2 : 1;

  return slugArr.slice(0, slugArr.length - separatorLength).join('-');
};
