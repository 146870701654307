import { useAppSelector } from 'hooks/redux';
import { getCheckoutLoading } from 'modules/ScrShopNew/store/slices/global/selectors';
import {
  getCheckoutIDSelector,
  getCheckoutProducts
} from 'modules/ScrShopNew/store/slices/checkout/selectors';

export const useIsCheckoutReady = () => {
  const checkoutID = useAppSelector(getCheckoutIDSelector);
  const isCheckoutLoading = useAppSelector(getCheckoutLoading);
  const checkoutProducts = useAppSelector(getCheckoutProducts);
  const { emptyCartProductSuggestion = { enabled: false } } = useAppSelector(
    (state) => state.banners
  );

  return !(
    !isCheckoutLoading &&
    !emptyCartProductSuggestion.enabled &&
    (!checkoutID || (checkoutID && !checkoutProducts.length))
  );
};
