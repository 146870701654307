import { useRef, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'modules/ScrShopNew/components/Forms';
import { Col, Form, Modal, Row } from 'reactstrap';
import { Button } from 'modules/ScrShopNew/components/Buttons';
import { resetPassword } from 'modules/ScrShopNew/store/slices/shop/actions';
import {
  setForgotPasswordFlowStatus,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShopNew/store/slices/shop';
import { forgotPasswordFlowStatusSelector } from 'modules/ScrShopNew/store/slices/shop/selectors';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { getTranslationKey } from 'helpers/texting';
import { useIsShop } from 'hooks/useIsShop';
import { StyledCloseIcon } from 'modules/ScrShopNew/styles/modalDetails';

import styled from 'styled-components';

const StyledModalHeader = styled(ModalHeader)<{ isShop?: boolean }>`
  && {
    border: 0;
    padding: 15px 30px !important;
    font-family: 'Noto Sans', sans-serif;
    position: relative;

    .modal-title {
      font-size: ${({ isShop }) => (isShop ? '30px' : 'initial')};
      font-weight: 300;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0 30px 20px 30px;
  font-family: 'Noto Sans', sans-serif;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const StyledInput = styled(Input)`
  input {
    border-radius: 0 !important;
    border: 1px solid #dedddd !important;
  }
`;

export function ForgotPasswordModal() {
  const dispatch = useDispatch();
  const {
    emailDoesNotExists,
    showSuccessMessage,
    showForgotPasswordModal,
    redirectToLoginModal,
    email
  } = useSelector(forgotPasswordFlowStatusSelector);
  const ref = useRef<RefObject<HTMLInputElement>>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const isShop = useIsShop();

  const setEmail = (email: string) =>
    dispatch(setForgotPasswordFlowStatus({ email: email.toLowerCase() }));
  const toggleModal = () =>
    dispatch(setForgotPasswordFlowStatus({ showForgotPasswordModal: !showForgotPasswordModal }));

  const onClose = () => {
    dispatch(
      setForgotPasswordFlowStatus({
        showForgotPasswordModal: !showForgotPasswordModal,
        showSuccessMessage: false,
        emailDoesNotExists: false,
        redirectToLoginModal: false,
        email: ''
      })
    );

    if (redirectToLoginModal) {
      dispatch(setForgotPasswordFlowStatus({ redirectToLoginModal: false }));
      dispatch(toggleLoginWithPasswordModalAction());
    }
  };

  const onEmailSubmit = (event: any) => {
    event.preventDefault();
    dispatch(resetPassword(email.toLowerCase()));
  };

  const onSuccessMessageSubmit = (event: any) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Modal centered isOpen={showForgotPasswordModal} toggle={toggleModal} size="md" zIndex={999999}>
      <StyledModalHeader isShop={isShop} toggle={!isShop && toggleModal}>
        <span>{getTranslationKey('forgotPasswordModal.title')}</span>
        {isShop && <StyledCloseIcon onClick={toggleModal} />}
      </StyledModalHeader>
      <StyledModalBody>
        {!showSuccessMessage ? (
          <Form onSubmit={onEmailSubmit}>
            <Row>
              <Col xs="12">
                <p>{getTranslationKey('forgotPasswordModal.text')}</p>
                <StyledInput
                  ref={ref}
                  name="email"
                  type="email"
                  label={getTranslationKey('shop.forms.emailTitle')}
                  placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                  value={email}
                  onChange={(e: React.BaseSyntheticEvent) => setEmail(e.currentTarget.value)}
                />
              </Col>
              <Col xs="12">
                {emailDoesNotExists && (
                  <p className="text-center">
                    {getTranslationKey('loginAccountModal.emailNotFound')}
                  </p>
                )}
                <StyledButton disabled={false} type="submit">
                  {getTranslationKey('forgotPasswordModal.resetPassword').toUpperCase()}
                </StyledButton>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form onSubmit={onSuccessMessageSubmit}>
            <p>{getTranslationKey('forgotPasswordModal.successMessage')}</p>
            {/* input should be present in order to submit the form with an Enter */}
            <input
              autoFocus
              ref={hiddenInputRef}
              onBlur={() => hiddenInputRef?.current?.focus()}
              style={{ opacity: 0, height: 0, padding: 0, border: 0 }}
            />
            <Button disabled={false} onClick={onClose} type="submit">
              {getTranslationKey('forgotPasswordModal.close').toUpperCase()}
            </Button>
          </Form>
        )}
      </StyledModalBody>
    </Modal>
  );
}
