import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import styled from 'styled-components';

import { useAppSelector } from 'hooks/redux';
import { stripTrailingSlash } from 'helper';
import { ACTIONS, ACTIONS_DATA, SCOPES } from 'old-store/constants/statistics';
import { logStatistics, setCurrentGallery } from 'old-store/actions';
import { isShopActiveSelector } from 'modules/ScrShop/store/selectors';
import { UnstyledButton } from 'styles/Button';
import { ShareIcon } from 'components/icons/ShareIcon';
import { ShopIcon } from 'components/icons/ShopIcon';
import { ContactIcon } from 'components/icons/ContactIcon';
import { ImageIcon } from 'components/icons/ImageIcon';
import { getShopUrl } from 'helpers/shop';
import queryString from 'query-string';

interface IMenuItem {
  icon: React.ReactNode;
  to: string;
  isHidden?: boolean;
  scope?: string;
}

const Wrapper = styled.div<{ hasBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  border-top: ${(props) => (props.hasBorder ? '1px solid var(--secondary-color)' : 'none')};
  padding-right: 25px;
  width: 100%;
`;

const MenuItemWrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  border-top: ${(props) => (props.active ? '3px solid var(--secondary-color)' : 'none')};
  color: ${(props) => props.theme?.colors?.appMenuIconsColor};
  svg {
    fill: ${(props) => props.theme?.colors?.appMenuIconsColor};
    stroke: ${(props) => props.theme?.colors?.appMenuIconsColor};
  }
`;

const MenuItem: React.FC<{ item: IMenuItem }> = ({ item }) => {
  const { currentGallery } = useAppSelector((state) => state.gallery);
  const { templateName } = useAppSelector((state) => state.collection);

  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();

  if (item.isHidden) return null;

  const locationPathname = stripTrailingSlash(pathname);

  let goToPath = `${item.to}`;

  if (!item.to && currentGallery && !locationPathname.includes('gallery')) {
    goToPath = `/gallery/${currentGallery._id}`;
  }

  const handleClick = () => {
    if (item.scope) {
      dispatch(
        logStatistics({
          action: ACTIONS.VIEW,
          scope: item.scope,
          actionData: ACTIONS_DATA.PAGE
        })
      );
    }

    if (goToPath === '/') {
      dispatch(setCurrentGallery(undefined));
    }

    // @ts-ignore
    sessionStorage.setItem(locationPathname, window.scrollY);

    history.push(goToPath);
  };

  const active = locationPathname === goToPath;

  // only show the active top indicator for the dark template
  return (
    <MenuItemWrapper active={active && templateName === 'dark'}>
      <UnstyledButton className={classnames(active)} onClick={handleClick}>
        {item.icon}
      </UnstyledButton>
    </MenuItemWrapper>
  );
};

export const BottomMenu: React.FC = () => {
  const { isApp } = queryString.parse(window.location.search);
  const { sharing, templateName, collectionCreator } = useAppSelector((state) => state.collection);
  const shopActive = useAppSelector(isShopActiveSelector);

  const { featureFlags } = collectionCreator || {};
  const { newShop } = featureFlags || {};

  const appPrefix = isApp ? `?isApp=${isApp}` : '';

  const items = [
    { icon: <ImageIcon data-cy="main-bottom-button" size={30} />, to: '/' + appPrefix },
    {
      icon: <ShopIcon data-cy="shop-bottom-button" size={30} />,
      to: `${getShopUrl(newShop)}` + appPrefix,
      isHidden: !shopActive
    },
    {
      icon: <ShareIcon data-cy="share-bottom-button" size={30} />,
      to: '/share' + appPrefix,
      scope: SCOPES.SHARE,
      isHidden: !sharing.show
    },
    {
      icon: <ContactIcon data-cy="contact-bottom-button" size={30} />,
      to: '/photographer' + appPrefix,
      scope: SCOPES.CONTACT
    }
  ];

  // For some reason the top border needs to be defined extra in this file for the SimpleFiligree
  // For all otehr styles it's handled in the underlying CSS

  return (
    <div className="nav-bottom-sticky text-center">
      <div className="row bottom-nav-row me-0">
        <Wrapper hasBorder={templateName === 'SimpleFiligree'}>
          {items.map((item) => (
            <MenuItem key={item.to} item={item} />
          ))}
        </Wrapper>
      </div>
    </div>
  );
};
