import { IDictionary } from 'modules/ScrShopNew/store/slices/global/types';

export const GALLERY_APPEAR_POSITION_DEFAULT = 10;

export const BANNERS_TEXT_KEYS: IDictionary<IDictionary<string>> = {
  largeGallery: {
    row1: 'banners.largeBannerGalleryRow1',
    row2: 'banners.largeBannerGalleryRow2',
    row2MinimumValue: 'banners.largeBannerGalleryRow2MinimumValue',
    asterisk: 'banners.largeBannerGalleryRow3Small',
    discountValue: 'banners.largeBannerGalleryDiscountValue',
    discountLabel: 'banners.largeBannerGalleryDiscountLabel',
    cta: 'banners.largeBannerGalleryCTA'
  },
  smallGallery: {
    row1: 'banners.smallBannerGalleryRow1',
    row2: 'banners.smallBannerGalleryRow2',
    row2MinimumValue: '',
    asterisk: 'banners.largeBannerGalleryRow3Small',
    discountValue: '',
    discountLabel: '',
    cta: 'banners.smallBannerGalleryCTA'
  },
  largeShop: {
    row1: 'banners.largeBannerShopRow1',
    row2: 'banners.largeBannerShopRow2',
    row2MinimumValue: '',
    asterisk: 'banners.largeBannerShopRow3Small',
    discountValue: 'banners.largeBannerShopDiscountValue',
    discountLabel: 'banners.largeBannerShopDiscountLabel',
    cta: 'banners.largeBannerShopCTA',
    freeShippingForAllOrders: 'banners.freeShippingForAllOrders',
    cartNeedsMoreProductsForFreeShipping: 'banners.cartNeedsMoreProductsForFreeShipping',
    freeShippingAvailableForCart: 'banners.freeShippingAvailableForCart'
  },
  smallShop: {
    row1: 'banners.smallBannerShopRow1',
    row2: 'banners.smallBannerShopRow2',
    row2MinimumValue: '',
    asterisk: 'banners.largeBannerShopRow3Small',
    discountValue: '',
    discountLabel: '',
    cta: 'banners.smallBannerShopCTA',
    freeShippingForAllOrders: 'banners.freeShippingForAllOrders',
    cartNeedsMoreProductsForFreeShipping: 'banners.cartNeedsMoreProductsForFreeShipping',
    freeShippingAvailableForCart: 'banners.freeShippingAvailableForCart'
  },
  betweenImages1: {
    row1: 'banners.betweenImagesTitle1',
    row2: 'banners.betweenImagesText1',
    row2MinimumValue: '',
    asterisk: 'banners.largeBannerGalleryRow3Small',
    discountValue: '',
    discountLabel: '',
    cta: 'banners.betweenImagesCTA1'
  },
  betweenImages2: {
    row1: 'banners.betweenImagesTitle2',
    row2: 'banners.betweenImagesText2',
    row2MinimumValue: '',
    asterisk: 'banners.largeBannerGalleryRow3Small',
    discountValue: '',
    discountLabel: '',
    cta: 'banners.betweenImagesCTA2'
  },
  betweenImages3: {
    row1: 'banners.betweenImagesTitle3',
    row2: 'banners.betweenImagesText3',
    row2MinimumValue: '',
    asterisk: 'banners.largeBannerGalleryRow3Small',
    discountValue: '',
    discountLabel: '',
    cta: 'banners.betweenImagesCTA3'
  }
};
