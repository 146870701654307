import { createSlice } from '@reduxjs/toolkit';
import { ICheckoutFlowReducer } from 'modules/ScrShopNew/store/slices/checkout-flow/types';

import { cloneDeep } from 'lodash';

export const DEFAULT_CHECKOUT_FLOW_STORE: ICheckoutFlowReducer = {
  isShowLoginFormModal: false,
  isShowShippingForm: true,
  isShowShippingEditForm: false,
  isShowOverview: false,
  isPricingUpdating: false,
  loginForm: {
    email: {
      value: '',
      error: ''
    },
    password: {
      value: '',
      error: ''
    }
  }
};

const initialState: ICheckoutFlowReducer = cloneDeep(DEFAULT_CHECKOUT_FLOW_STORE);

const checkoutFlowSlice = createSlice({
  name: 'checkoutFlow',
  initialState,
  reducers: {
    setCheckOutFlowAction(state, action) {
      return { ...state, ...action.payload };
    },
    cleanUpCheckoutFlowAction() {
      return cloneDeep(DEFAULT_CHECKOUT_FLOW_STORE);
    }
  }
});

export const { setCheckOutFlowAction, cleanUpCheckoutFlowAction } = checkoutFlowSlice.actions;

export const checkoutFlowReducer = checkoutFlowSlice.reducer;
