import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCookie } from 'helpers/cookie';
import { RootState } from 'old-store/store';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import TagManager from 'react-gtm-module';
import { IProduct } from 'modules/ScrShopNew/store/slices/products/types';
import { IShopOptions } from 'modules/ScrShopNew/store/slices/shop/types';
import { getProductTrackingData, trackProducts } from 'old-store/utils';
import { compact, values, omit, filter, map } from 'lodash';
import { getSelectedImagesIDsSelector } from 'modules/ScrShopNew/store/slices/shop/selectors';
import { getProductByID } from 'modules/ScrShopNew/store/slices/products/selectors';
import { getCheckoutIDSelector } from './selectors';
import {
  IAddProductToCartAction,
  IChangeCheckoutProductQuantityAction,
  ICheckoutProduct,
  IRecalculatePricingAction,
  IRemoveCheckoutProductAction
} from 'modules/ScrShopNew/store/slices/checkout/types';
import { getCheckoutProducts } from 'modules/ScrShopNew/store/slices/checkout/selectors';
import { setCheckOutFlowAction } from 'modules/ScrShopNew/store/slices/checkout-flow';
import { fetchProductSuggestion } from 'modules/ScrShopNew/store/slices/products/actions';
import { selectBanners } from 'store/slices/banners/selectors';
import {
  addUpdateProductToCartSuccessAction,
  cleanUpCheckoutAction,
  updateCheckoutStoreAction
} from 'modules/ScrShopNew/store/slices/checkout';
import { HttpStatus } from 'constants/statusCodes';
import { getTranslationKey } from 'helpers/texting';
import { toast } from 'react-toastify';

interface IAddProductPayload {
  selectionId?: string;
  callback?: () => void;
  photobookId?: string;
  product?: IProduct;
}

export const fetchCheckoutAction = createAsyncThunk(
  'checkout/fetch',
  async (_, { dispatch, getState, rejectWithValue }) => {
    // yield take(setCollection);
    // yield take(saveEndCustomer);
    const state = getState() as RootState;
    const { authed, preAuthed } = state.endCustomer;
    const { _user } = state.collection; // Assuming this is where you get it from; adjust accordingly.

    const cookiesCheckoutID = getCookie(`checkoutID_${window.SITE_ID}`);
    if (!cookiesCheckoutID && !_user) {
      return rejectWithValue('No checkout ID and no user found');
    }

    const isAnonymous = !(authed || preAuthed);
    const apiMethod = !isAnonymous ? Api.Cart.fetchActiveCart : Api.Cart.fetchCart;
    const data = !isAnonymous
      ? {
          collectionId: window.SITE_ID
        }
      : {
          collection_id: window.SITE_ID,
          shoppingCart_id: cookiesCheckoutID
        };

    // @ts-ignore
    const response = await apiMethod(data);
    ApiErrors.checkOnApiError(response);

    const cart = !isAnonymous ? response.result : response;
    const validStatuses = ['active', 'converted', 'abandoned'];
    if (validStatuses.includes(cart.status)) {
      dispatch(addUpdateProductToCartSuccessAction(cart));
    } else {
      document.cookie = `checkoutID_${window.SITE_ID}=; Max-Age=-99999999;`;
    }

    return cart;
  }
);

export const claimCheckoutAction = createAsyncThunk(
  'checkout/claim',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const cookiesCheckoutID = getCookie(`checkoutID_${window.SITE_ID}`);
    if (!cookiesCheckoutID) return rejectWithValue('No checkout ID found');

    const {
      checkout: { _endCustomer }
    } = getState() as RootState;

    try {
      let response;
      if (_endCustomer) {
        response = await Api.Cart.fetchCart({
          collection_id: window.SITE_ID,
          shoppingCart_id: cookiesCheckoutID
        });
      } else {
        response = await Api.Cart.claimCart({
          collection_id: window.SITE_ID,
          shoppingCart_id: cookiesCheckoutID
        });
        ApiErrors.checkOnApiError(response); // Ensure this is suitable for async usage or adjust as needed
      }

      if (response.status === 'active') {
        try {
          dispatch(addUpdateProductToCartSuccessAction(response));
        } catch (err) {
          console.log('Error updating cart:', err);
        }
      } else {
        console.warn('Checkout Cookie deleted');
        localStorage.removeItem(`checkout_${window.SITE_ID}`);
        document.cookie = `checkoutID_${window.SITE_ID}=; Max-Age=-99999999;`;

        return rejectWithValue('Non-active cart status');
      }

      return response;
    } catch (e: any) {
      console.error('An error occurred:', e);

      return rejectWithValue(e.message || 'An error occurred during cart claiming');
    }
  }
);

const trackAddProduct = (product: IProduct, options: IShopOptions) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              ...getProductTrackingData(product, null, compact(values(options)).join('-'), 1)
            }
          ]
        }
      },
      dataLayerName: window.SHOP_DATA_LAYER_NAME
    });
  } catch (e) {
    console.log(e);
  }
};

export const addProductToCartAction = createAsyncThunk(
  'cart/addProductToCart',
  async (payload: IAddProductPayload | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { croppedImages, selectedProductID, cartSelectedProductID, options } = state.shop;
    const selectedImages = getSelectedImagesIDsSelector(state);
    const selectedProduct = getProductByID(state)(selectedProductID);
    const product = selectedProduct || payload?.product;
    const cartID = getCheckoutIDSelector(state);

    const croppedImagesOrdered = selectedImages.map((imageId) => ({
      ...croppedImages[`${product?.slug}-${imageId}`],
      _image: imageId
    }));

    const images = croppedImagesOrdered.map((image, index) => ({
      _image: image._image,
      crop: image,
      // @ts-ignore
      quantity: image && image.quantity ? image.quantity : 1,
      order: index + 1
    }));

    let requestData: IAddProductToCartAction = {
      collection_id: window.SITE_ID,
      product_id: product._id,
      // @ts-ignore
      images,
      selectedOptions: compact(values(options)),
      ...(payload ? { selectionId: payload.selectionId } : {})
    };

    if (payload?.photobookId) {
      requestData = omit(requestData, 'images', 'selectedOptions');
      requestData.photobook_id = payload.photobookId;
      requestData.images = [];
    }

    if (cartID) {
      requestData.shoppingCart_id = cartID;
    }

    // Indicates if shop in "edit" mode
    if (cartSelectedProductID) {
      requestData.shoppingCartProduct_id = cartSelectedProductID;
      requestData = omit(requestData, 'product_id');
    } else {
      trackAddProduct(product as IProduct, options);
    }

    try {
      const response = await Api.Cart[
        !cartSelectedProductID ? 'addProduct' : 'updateShoppingCartProduct'
      ](requestData as any);

      if (payload?.photobookId && response.code === HttpStatus.BadRequest) {
        toast.error(getTranslationKey('photobook.fillTheSpreadsError'), {
          theme: 'colored',
          autoClose: 3000,
          hideProgressBar: true
        });
      }

      ApiErrors.checkOnApiError(response);

      dispatch(addUpdateProductToCartSuccessAction(response));

      payload?.callback?.();
    } catch (e) {
      console.log(e);
    }
  }
);

export const deleteProductFromCheckoutAction = createAsyncThunk(
  'checkout/deleteProduct',
  async (payload: IRemoveCheckoutProductAction, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const products = getCheckoutProducts(state);
      const { emptyCartProductSuggestion = { enabled: false } } = selectBanners(state);

      if (products.length === 1) {
        if (emptyCartProductSuggestion.enabled === true) {
          dispatch(
            fetchProductSuggestion({
              collectionId: window.SITE_ID,
              excludeProducts: products.map((product: ICheckoutProduct) => product._id)
            })
          );
        }

        dispatch(cleanUpCheckoutAction());
      } else {
        const filteredProducts = filter(products, (product) => product._id !== payload.productID);
        dispatch(
          updateCheckoutStoreAction({
            products: filteredProducts
          })
        );
      }

      const response = await Api.Cart.deleteProduct(payload);
      ApiErrors.checkOnApiError(response);
      dispatch(updateCheckoutStoreAction(response));
    } catch (e) {
      console.error(e);

      return rejectWithValue('Failed to delete product from checkout');
    }
  }
);

const lastQuantityMap = {};
export const changeCheckoutProductQuantityAction = createAsyncThunk(
  'checkout/changeProductQuantity',
  async (
    payload: IChangeCheckoutProductQuantityAction,
    { getState, dispatch, rejectWithValue }
  ) => {
    const products = getCheckoutProducts(getState() as RootState);
    let trackProduct: Partial<ICheckoutProduct> = {};
    let initialQuantity = 0;

    if (!payload.quantity) {
      return rejectWithValue('Quantity not specified');
    }

    const updatedProducts = products.map((product) => {
      const quantity =
        product._id === payload.shoppingCartProduct_id ? payload.quantity : product.quantity;

      if (product._id === payload.shoppingCartProduct_id) {
        trackProduct = product;
        initialQuantity = product.quantity;
      }

      lastQuantityMap[product._id] = quantity;

      return { ...product, quantity };
    });

    dispatch(updateCheckoutStoreAction({ products: updatedProducts }));
    dispatch(setCheckOutFlowAction({ isPricingUpdating: true }));

    if (payload.quantity < initialQuantity) {
      // Adjust according to how you want to track products, assuming trackProducts is adapted for RTK
      trackProducts(
        [trackProduct as ICheckoutProduct],
        'remove_from_cart',
        {},
        { quantity: payload.quantity }
      );
    }

    try {
      const response = await Api.Cart.changeProductQuantity(payload);
      ApiErrors.checkOnApiError(response);

      const updatedProductsList = map(response.products, (product) => ({
        ...omit(product, ['quantity']),
        quantity: lastQuantityMap[product._id]
      }));

      dispatch(setCheckOutFlowAction({ isPricingUpdating: false }));
      dispatch(updateCheckoutStoreAction({ ...response, products: updatedProductsList }));
    } catch (e) {
      console.error(e);

      return rejectWithValue('Failed to change product quantity in checkout');
    }
  }
);

export const cleanCheckout = () => {
  localStorage.removeItem(`checkout_${window.SITE_ID}`);
  localStorage.removeItem(`orders_${window.SITE_ID}`);
  document.cookie = `checkoutID_${window.SITE_ID}=`;
};

export const recalculatePricingAction = createAsyncThunk(
  'checkout/recalculatePricing',
  async (payload: IRecalculatePricingAction, { dispatch, rejectWithValue }) => {
    dispatch(setCheckOutFlowAction({ isPricingUpdating: true }));
    try {
      const response = await Api.Cart.recalculatePricing(payload);
      ApiErrors.checkOnApiError(response);
      dispatch(updateCheckoutStoreAction(response.result));
      dispatch(setCheckOutFlowAction({ isPricingUpdating: false }));
    } catch (e) {
      console.error(e);
      dispatch(setCheckOutFlowAction({ isPricingUpdating: false }));

      return rejectWithValue('Failed to recalculate pricing');
    }
  }
);
