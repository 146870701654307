import { useRef, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'modules/ScrShopNew/components/Forms';
import { Col, Form, Modal, Row } from 'reactstrap';
import {
  setCreateAccountWithPasswordFlowStatus,
  toggleLoginWithPasswordModalAction
} from 'modules/ScrShopNew/store/slices/shop';
import {
  checkEndCustomerEmail,
  createAccountWithPassword
} from 'modules/ScrShopNew/store/slices/shop/actions';
import { signupWithPasswordFlowStatusSelector } from 'modules/ScrShopNew/store/slices/shop/selectors';
import { Button } from 'modules/ScrShopNew/components/Buttons';
import { EnterPasswordModal } from '../LoginWithPasswordAccountModal/EnterPasswordModal';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { LinkBtn } from 'components/IconButton/LinkButton';
import { getTranslationKey } from 'helpers/texting';
import { useToggle } from 'hooks/useToggle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { setCheckOutFlowAction } from 'modules/ScrShopNew/store/slices/checkout-flow';
import { useIsShop } from 'hooks/useIsShop';
import { StyledCloseIcon } from 'modules/ScrShopNew/styles/modalDetails';

const StyledModalHeader = styled(ModalHeader)<{ isShop?: boolean }>`
  && {
    border: 0;
    padding: 15px 30px !important;
    font-family: 'Noto Sans', sans-serif;
    position: relative;

    .modal-title {
      font-size: ${({ isShop }) => (isShop ? '30px' : 'initial')};
      font-weight: 300;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0 30px 20px 30px;
  font-family: 'Noto Sans', sans-serif;
`;

const StyledForm = styled(Form)`
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;

  .input-element {
    border: 1px solid #e5e5e5 !important;
    border-radius: 0 !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const StyledLinkBtn = styled(LinkBtn)`
  && {
    margin: 20px auto 0 auto !important;
    width: fit-content;
  }
`;

export function CreateAccountEmailModal() {
  const dispatch = useDispatch();
  const ref = useRef<RefObject<HTMLInputElement>>(null);
  const { toggle: isValidated, openToggle: enableValidation } = useToggle();
  const { showEmailModal, showPasswordModal, email, password, emailIsAlreadyTaken } = useSelector(
    signupWithPasswordFlowStatusSelector
  );
  const isShop = useIsShop();

  const setEmail = (email: string) => dispatch(setCreateAccountWithPasswordFlowStatus({ email }));

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(getTranslationKey('checkEmailFormat'))
        .required(getTranslationKey('mandatoryField'))
    }),
    onSubmit: async (values, { resetForm }) => {
      setEmail(values.email);
      dispatch(checkEndCustomerEmail(values.email.toLowerCase()));
      resetForm();
    }
  });

  const setPassword = (password: string) =>
    dispatch(setCreateAccountWithPasswordFlowStatus({ password }));
  const toggleModal = () =>
    dispatch(setCreateAccountWithPasswordFlowStatus({ showEmailModal: !showEmailModal }));
  const togglePasswordModal = () =>
    dispatch(setCreateAccountWithPasswordFlowStatus({ showPasswordModal: !showPasswordModal }));

  const toggleExistingModals = () => {
    if (showEmailModal) toggleModal();
    if (showPasswordModal) togglePasswordModal();
  };

  const openLoginModal = () => {
    toggleExistingModals();
    dispatch(toggleLoginWithPasswordModalAction());
  };

  const onPasswordSubmit = (password: string) => {
    dispatch(
      createAccountWithPassword({
        password,
        email: email.toLowerCase(),
        collectionId: window.SITE_ID,
        firstName: '',
        lastName: ''
      })
    );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const hasErrors = Object.values(errors).length;

    enableValidation();
    if (!hasErrors) handleSubmit();
  };

  const onAlreadyExists = () => {
    if (isShop) {
      toggleExistingModals();
      dispatch(setCheckOutFlowAction({ isShowLoginFormModal: true }));
    } else {
      openLoginModal();
    }
  };

  return (
    <>
      <Modal
        data-cy="component-create-profile"
        centered
        isOpen={showEmailModal}
        toggle={toggleModal}
        size="md"
        // @ts-ignore
        zIndex={999999}
      >
        <StyledModalHeader toggle={!isShop && toggleModal} isShop={isShop}>
          <span>{getTranslationKey('createAccountModal.title')}</span>
          {isShop && <StyledCloseIcon onClick={toggleModal} />}
        </StyledModalHeader>

        <StyledModalBody>
          <StyledForm onSubmit={onSubmit}>
            <Row>
              <Col xs="12">
                <StyledInput
                  ref={ref}
                  data-cy="component-input-email"
                  name="email"
                  type="email"
                  label={getTranslationKey('shop.forms.emailTitle')}
                  placeholder={getTranslationKey('shop.forms.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorText={isValidated ? errors.email : ''}
                />
              </Col>
              <Col xs="12">
                {emailIsAlreadyTaken && (
                  <p className="text-center">
                    {getTranslationKey('signupWithPassword.emailIsAlreadyTaken')}
                  </p>
                )}
                <StyledButton disabled={false} type="submit" data-cy="component-submit-button">
                  {getTranslationKey('loginAccountModal.loginFromShopContinue').toUpperCase()}
                </StyledButton>
                <StyledLinkBtn onClick={onAlreadyExists} onKeyDown={onAlreadyExists}>
                  {getTranslationKey('createAccountModal.alreadyAccount')}
                </StyledLinkBtn>
              </Col>
            </Row>
          </StyledForm>
        </StyledModalBody>
      </Modal>
      <EnterPasswordModal
        title={getTranslationKey('createAccountModal.title')}
        text={getTranslationKey('signupWithPassword.enterPasswordText')}
        submitButtonLabel={getTranslationKey('signupWithPassword.createAccount').toUpperCase()}
        showEnterPasswordModal={showPasswordModal}
        passwordIsInvalid={false}
        password={password}
        footerLinkText={getTranslationKey('signupWithPassword.returnToLogin')}
        onSubmit={onPasswordSubmit}
        toggleModal={togglePasswordModal}
        setPassword={setPassword}
        onFooterLinkClick={openLoginModal}
      />
    </>
  );
}
