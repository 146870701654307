import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import {
  IFetchProductsAction,
  IGetProductSuggestionAction
} from 'modules/ScrShopNew/store/slices/products/types';
import { selectBanners } from 'store/slices/banners/selectors';
import { RootState } from 'old-store/store';
import { DEFAULT_COUPON } from 'constants/coupons';
import { fetchProductSuggestionSuccess, fetchProductsActionSuccess } from '.';
import { setGlobalShopStatus } from 'store/slices/collection';

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (payload: IFetchProductsAction, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { couponCodeAdvertising = { coupon: DEFAULT_COUPON } } = selectBanners(state);
      const { id, active } = couponCodeAdvertising.coupon;
      if (id && active) {
        // eslint-disable-next-line no-param-reassign
        payload.couponId = id;
      }

      const result = await Api.Products.get(payload);

      ApiErrors.checkOnApiError(result);
      dispatch(fetchProductsActionSuccess(result));
    } catch (err) {
      dispatch(setGlobalShopStatus(false));
      console.error(err);
    }
  }
);

export const fetchProductSuggestion = createAsyncThunk(
  'products/fetchProductSuggestion',
  async (payload: IGetProductSuggestionAction, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { couponCodeAdvertising = { coupon: DEFAULT_COUPON } } = selectBanners(state);
      const { id, active } = couponCodeAdvertising.coupon;
      if (id && active) {
        // eslint-disable-next-line no-param-reassign
        payload.couponId = id;
      }

      const response = await Api.Products.getSuggestion(payload);
      ApiErrors.checkOnApiError(response);
      dispatch(fetchProductSuggestionSuccess(response.result));
    } catch (e) {
      dispatch(setGlobalShopStatus(false));
      console.error(e);
    }
  }
);
