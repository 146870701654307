import { checkoutReducer } from './slices/checkout';
import { checkoutFlowReducer } from './slices/checkout-flow';
import { countriesReducer } from './slices/countries';
import { errorReducer } from './slices/error';
import { globalReducer } from './slices/global';
import { ordersReducer } from './slices/orders';
import { productsReducer } from './slices/products';
import { shippingReducer } from './slices/shipping';
import { shopReducer } from './slices/shop';

export const reducers = {
  checkout: checkoutReducer,
  checkoutFlow: checkoutFlowReducer,
  countries: countriesReducer,
  error: errorReducer,
  global: globalReducer,
  orders: ordersReducer,
  products: productsReducer,
  shipping: shippingReducer,
  shop: shopReducer
};
