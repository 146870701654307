import { Alert, Col } from 'reactstrap';
import { getTranslationKey } from 'helpers/texting';

import styled from 'styled-components';
import moment from 'moment';

interface IOrderDealineBanner {
  colMd: number | undefined;
  colXs: number | undefined;
}

const StyledAlert = styled(Alert)`
  margin: 10px;
`;

const TextHeadline = styled.span`
  font-weight: bold;
`;

const List = styled.ul`
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 25px;
`;

const Text = styled.span``;

export const OrderDeadlineBanner: React.FC<IOrderDealineBanner> = ({ colMd = 12, colXs = 12 }) => {
  const showBanner = moment().isBetween(moment('2025-01-01'), moment('2025-04-18'));

  if (!showBanner) return null;

  return (
    <Col md={colMd} xs={colXs}>
      <StyledAlert color="danger">
        <TextHeadline>{getTranslationKey('banners.easterDeadlineTitle')}</TextHeadline>

        <List>
          <li>{getTranslationKey('banners.easterDealinePointOne')}</li>
          <li>{getTranslationKey('banners.easterDealinePointTwo')}</li>
        </List>

        <Text>{getTranslationKey('banners.easterDeadlineDescription')}</Text>
      </StyledAlert>
    </Col>
  );
};
