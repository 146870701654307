import { useRef, RefObject, useState } from 'react';
import { Col, Form, Modal, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginFlowStatusSelector } from 'modules/ScrShopNew/store/slices/shop/selectors';
import { setForgotPasswordFlowStatus } from 'modules/ScrShopNew/store/slices/shop';
import { Input } from 'modules/ScrShopNew/components/Forms';
import { Button } from 'modules/ScrShopNew/components/Buttons';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ModalBody } from 'components/Modal/ModalBody';
import { SendToPhotographerIcon } from 'components/icons/SendToPhotographerIcon';
import { LinkBtn } from 'components/IconButton/LinkButton';
import { useIsShop } from 'hooks/useIsShop';
import { getTranslationKey } from 'helpers/texting';
import { useTheme } from 'hooks/useTheme';
import { StyledCloseIcon } from 'modules/ScrShopNew/styles/modalDetails';

import styled from 'styled-components';

interface IEnterPasswordModal {
  title: string;
  showEnterPasswordModal: boolean;
  password: string;
  text: string;
  passwordIsInvalid: boolean;
  submitButtonLabel: string;
  footerLinkText?: string;
  onSubmit: (password: string) => void;
  toggleModal: () => void;
  setPassword: (password: string) => void;
  onFooterLinkClick?: () => void;
}

const StyledModalHeader = styled(ModalHeader)<{ isShop?: boolean }>`
  && {
    border: 0;
    padding: 15px 30px !important;
    font-family: 'Noto Sans', sans-serif;
    position: relative;

    .modal-title {
      font-size: ${({ isShop }) => (isShop ? '30px' : 'initial')};
      font-weight: 300;
      margin-right: 30px;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0 30px 20px 30px;
  font-family: 'Noto Sans', sans-serif;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;

  .input-element {
    border: 1px solid #e5e5e5 !important;
    border-radius: 0 !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;

  &:hover {
    svg {
      * {
        stroke: #000 !important;
      }
    }
  }
`;

export const EnterPasswordModal: React.FC<IEnterPasswordModal> = ({
  title,
  showEnterPasswordModal,
  text,
  password,
  passwordIsInvalid,
  footerLinkText,
  submitButtonLabel,
  onSubmit,
  toggleModal,
  setPassword,
  onFooterLinkClick
}) => {
  const [theme] = useTheme();
  const dispatch = useDispatch();
  const [isTooShortPass, setIsTooShortPass] = useState(false);
  const { email, endCustomerHasPassword } = useSelector(loginFlowStatusSelector);
  const isShop = useIsShop();
  const ref = useRef<RefObject<HTMLInputElement>>(null);

  const openForgotPasswordModal = () => {
    toggleModal();
    dispatch(
      setForgotPasswordFlowStatus({
        showForgotPasswordModal: true,
        redirectToLoginModal: true,
        email: email.toLowerCase()
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length >= 6) onSubmit(password);
    else setIsTooShortPass(true);
  };

  return (
    <Modal
      data-cy="component-password-modal"
      centered
      isOpen={showEnterPasswordModal}
      toggle={toggleModal}
      size="md"
      // @ts-ignore
      zIndex={999999}
    >
      <StyledModalHeader isShop={isShop} toggle={!isShop && toggleModal}>
        <span>{title}</span>
        {isShop && <StyledCloseIcon onClick={toggleModal} />}
      </StyledModalHeader>
      <StyledModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs="12">
              <p data-cy="component-show-email">{text}</p>
              <StyledInput
                ref={ref}
                data-cy="component-password-input"
                name="password"
                type="password"
                label={getTranslationKey('shop.forms.passwordPlaceHolder')}
                placeholder={getTranslationKey('shop.forms.passwordPlaceHolder')}
                value={password}
                onChange={(e: React.BaseSyntheticEvent) => setPassword(e.currentTarget.value)}
                errorText={
                  isTooShortPass ? getTranslationKey('enterPasswordModal.passwordIsTooShort') : ''
                }
                withEye
              />
            </Col>
            <Col xs="12">
              {passwordIsInvalid && (
                <p className="text-center" data-cy="component-modal-message">
                  {getTranslationKey('enterPasswordModal.passwordIsInvalid')}
                </p>
              )}
              <StyledButton
                data-cy="component-submit-button"
                disabled={false}
                type="submit"
                onClick={handleSubmit}
                $iconSide="left"
              >
                <SendToPhotographerIcon
                  size={16}
                  color={isShop ? 'white' : theme.colors.menuIconsColor}
                />
                {submitButtonLabel}
              </StyledButton>
              {endCustomerHasPassword && (
                <>
                  {onFooterLinkClick && footerLinkText && (
                    <LinkBtn onClick={onFooterLinkClick} onKeyDown={onFooterLinkClick}>
                      {footerLinkText}
                    </LinkBtn>
                  )}
                  <LinkBtn onClick={openForgotPasswordModal} onKeyDown={openForgotPasswordModal}>
                    {getTranslationKey('shop.forms.forgotPassword')}
                  </LinkBtn>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </StyledModalBody>
    </Modal>
  );
};
