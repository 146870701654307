import { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import { getShippingStore } from 'modules/ScrShop/store/selectors/shipping';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import {
  getFormValues,
  isFormHasError,
  trackProducts,
  validateFieldStore
} from 'modules/ScrShop/store/utils/helpers';
import {
  claimCheckoutAction,
  recalculatePricingAction,
  setCheckOutFlowAction,
  setShippingValueAction
} from 'modules/ScrShop/store/actions';
import { getCheckoutLoginForm } from 'modules/ScrShop/store/selectors/checkout-flow';
import { mapKeys, find, omit } from 'lodash';
import {
  getCheckoutProducts,
  getCheckoutIDSelector
} from 'modules/ScrShop/store/selectors/checkout';
import TagManager from 'react-gtm-module';
import {
  ICheckoutProduct,
  ISagaAction,
  UpdateCreateCustomerPayload
} from 'modules/ScrShop/store/types';
import { showGlobalError } from 'modules/ScrShop/store/actions/errors';
import db from 'database';
import { trackEventAction } from 'old-store/actions';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { setCookie } from 'helpers/cookie';
import { getTranslationKey } from 'helpers/texting';
import { fetchMessage } from 'store/slices/messages/actions';
import { HttpStatus } from 'constants/statusCodes';
import { fetchPhotobooks } from 'modules/ScrPhotobook/store/slices/photobook/actions';

const trackAddShippingInfo = (products: ICheckoutProduct[]) => {
  const isTrackAction =
    window.shopDataLayer && find(window.shopDataLayer, { event: 'add_shipping_info' });
  if (!isTrackAction) {
    trackProducts(products, 'add_shipping_info', {}, {});
  }
};

export const createUpdateCustomerSaga = function* ({
  payload
}: ISagaAction<UpdateCreateCustomerPayload>): SagaIterator {
  const shippingData = { ...(yield select(getShippingStore)) };
  const products = yield select(getCheckoutProducts);

  const itemsToOmit = ['billingAddress', 'isSameBillingAddress'];
  const shippingValue = omit(shippingData, itemsToOmit);

  mapKeys(shippingValue, (data, key) => {
    if (payload && payload.ignoreGtcAndPrivacyCheckbox && key === 'gtcAndPrivacyCheckbox') {
      shippingValue[key] = { ...shippingValue[key], error: '' };

      return undefined;
    }

    const error = validateFieldStore(key, data, shippingValue);
    if (error) {
      shippingValue[error.name] = { ...shippingValue[error.name], error: error.errorText };
    }
  });

  const hasError = isFormHasError(shippingValue);
  yield put(setShippingValueAction(shippingValue));

  if (hasError) return;

  const formValues: any = {
    collection_id: window.SITE_ID,
    ...getFormValues(shippingValue),
    billingAddress: {
      _country: shippingData?._country?.value
    }
  };

  try {
    const response = yield call(Api.Auth.updateOrCreateCustomer, formValues);
    ApiErrors.checkOnApiError(response);
    const { endCustomerToken, endCustomer, isNew } = response;
    setCookie('endCustomerToken', endCustomerToken, 30);
    yield put({ type: 'fetch_endCustomer', payload: endCustomer });
    yield put(saveEndCustomer({ ...endCustomer, authed: true }));

    const { _user } = yield select((store) => store.collection);

    if (isNew) {
      yield put(
        trackEventAction({
          name: 'user-signed-up',
          payload: { endCustomer, flyUserId: localStorage.getItem('flyUserId') }
        })
      );
    }

    localStorage.setItem('endCustomerToken', JSON.stringify({ [_user]: endCustomerToken }));
    if (endCustomer.type === 'fly') {
      localStorage.setItem('flyUserId', endCustomer._id);
    } else {
      localStorage.removeItem('flyUserId');
    }

    if (!(payload && payload.withoutCheckoutFlow)) {
      yield put(claimCheckoutAction());
      yield put(
        setCheckOutFlowAction({
          isShowShippingForm: false,
          isShowShippingEditForm: false,
          isShowLoginForm: false,
          isShowOverview: true
        })
      );

      const checkoutId = yield select(getCheckoutIDSelector);
      yield put(recalculatePricingAction({ cartId: checkoutId }));
    }

    trackAddShippingInfo(products);

    if (payload && payload.showSuccessMessage) {
      yield put(
        showGlobalError({
          title: 'Success',
          text: getTranslationKey('shop.shippingSections.accountUpdate')
        })
      );
    }
  } catch (e: any) {
    console.log(e);

    const { authed } = yield select((store) => store.endCustomer);

    if (authed && e.code === HttpStatus.Conflict) {
      return window.location.reload();
    }

    if (e.code === HttpStatus.Conflict) {
      yield put(
        setShippingValueAction({
          email: {
            // @ts-ignore
            ...shippingValue.email,
            error: getTranslationKey('shop.forms.backendEmailValidation')
          }
        })
      );
    }
  }
};

export const loginCustomerSaga = function* (): SagaIterator {
  const loginForm = yield select(getCheckoutLoginForm);
  const hasError = isFormHasError(loginForm);
  const products = yield select(getCheckoutProducts);
  if (hasError) {
    return;
  }
  const formValues: any = {
    collectionId: window.SITE_ID,
    ...getFormValues(loginForm)
  };

  try {
    // @ts-ignore
    yield db.sites.delete(window.SITE_ID);
    const response = yield call(Api.Auth.login, formValues);
    ApiErrors.checkOnApiError(response);
    const {
      result: { endCustomerToken, endCustomer }
    } = response;
    setCookie('endCustomerToken', endCustomerToken, 30);
    yield put({ type: 'fetch_endCustomer', payload: endCustomer });
    yield put(saveEndCustomer({ ...endCustomer, authed: true }));

    const { _user } = yield select((store) => store.collection);

    yield put(
      trackEventAction({
        name: 'user-signed-in',
        payload: { endCustomer, flyUserId: localStorage.getItem('flyUserId') }
      })
    );

    localStorage.setItem('endCustomerToken', JSON.stringify({ [_user]: endCustomerToken }));
    localStorage.removeItem('flyUserId');

    yield put(claimCheckoutAction());
    const isDigitalProduct = products.every((product) => product._productGroup === 'digital');
    const endCustomerCountry = endCustomer._country;
    const checkoutFlow = {
      isShowLoginForm: false,
      isShowShippingForm: false,
      isShowShippingEditForm: false,
      isShowOverview: false
    };

    if (!isDigitalProduct && !['germany', 'austria'].includes(endCustomerCountry)) {
      checkoutFlow.isShowShippingEditForm = true;
    } else {
      checkoutFlow.isShowOverview = true;
    }

    yield put(setCheckOutFlowAction(checkoutFlow));

    trackAddShippingInfo(products);
    TagManager.dataLayer({
      dataLayer: { event: 'login' },
      dataLayerName: window.SHOP_DATA_LAYER_NAME
    });

    // @ts-ignore
    yield put(fetchMessage());
    yield put(fetchPhotobooks({}));
  } catch (e: any) {
    if (e.code === HttpStatus.NotFound || e.code === HttpStatus.Forbidden) {
      yield put(
        setCheckOutFlowAction({
          loginForm: {
            ...loginForm,
            password: {
              // @ts-ignore
              ...loginForm.password,
              error: getTranslationKey('shop.forms.backendLoginValidation')
            }
          }
        })
      );
    }
    console.log(e);
  }
};
