import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDictionary } from 'modules/ScrShopNew/store/slices/global/types';
import {
  IPackage,
  IDigitalPricingList,
  IProductRecList
} from 'modules/ScrShopNew/store/slices/products/types';
import {
  IShopReducer,
  ISetOptionAction,
  ISetCropAction,
  ICropData,
  ISetCropBoxImageData,
  IChangeImageCountAction,
  ISetShopEditMode,
  IGlobalShopSettings,
  ILaboratory,
  IGroupedImages,
  IGroupedByGalleriesImages
} from 'modules/ScrShopNew/store/slices/shop/types';
import { cloneDeep } from 'lodash';

const initialData: IShopReducer = {
  selectedProductID: '',
  cartSelectedProductID: '',
  selectedImages: [],
  groupedImages: { horizontal: [], vertical: [], fallback: [] },
  groupedByGalleriesImages: { horizontal: {}, vertical: {}, fallback: {} },
  globalShopSettings: {
    id: '',
    userId: '',
    printShopActive: true
  },
  options: {
    border: '',
    finish: '',
    'border-color': '',
    color: '',
    surface: '',
    'color-combination': '',
    material: ''
  },
  croppedImages: {},
  isEditMode: false,
  galleriesToggleStates: {},
  upselling: {
    packages: [],
    fetchingPackages: false,
    digitalPricingList: null
  },
  forgotPasswordFlowStatus: {
    showForgotPasswordModal: false,
    showSuccessMessage: false,
    emailDoesNotExists: false,
    redirectToLoginModal: false,
    email: ''
  },
  laboratory: {
    id: '',
    name: '',
    default: false,
    shippingCountries: [],
    defaultShippingTime: {
      from: 7,
      to: 10
    },
    shippingTimeByProductGroups: []
  },
  loginFlowStatus: {
    showLoginWithPasswordModal: false,
    showEnterPasswordModal: false,
    endCustomerHasPassword: null,
    emailDoesNotExists: null,
    password: '',
    email: '',
    passwordIsInvalid: false
  },
  createAccountWithPasswordFlowStatus: {
    showEmailModal: false,
    showPasswordModal: false,
    emailIsAlreadyTaken: false,
    email: '',
    password: ''
  },
  productRecList: {
    id: '',
    createdAt: '',
    productPricingList: '',
    endCustomer: null,
    recommendationSystemVersion: '',
    products: []
  },
  productBestsellerList: {
    id: '',
    createdAt: '',
    productPricingList: '',
    endCustomer: null,
    recommendationSystemVersion: '',
    products: []
  }
};

const initialState: IShopReducer = cloneDeep(initialData);

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setSelectedProductIDAction: (state, action: PayloadAction<string>) => {
      state.selectedProductID = action.payload;
    },
    setSelectedImagesSuccessAction: (state, action: PayloadAction<string[]>) => {
      state.selectedImages = action.payload;
    },
    clearShopAction: (state) => {
      const {
        groupedImages,
        groupedByGalleriesImages,
        productRecList,
        productBestsellerList,
        options
      } = state;

      const newInitialData = cloneDeep(initialData);

      newInitialData.groupedImages = groupedImages;
      newInitialData.groupedByGalleriesImages = groupedByGalleriesImages;
      newInitialData.productRecList = productRecList;
      newInitialData.productBestsellerList = productBestsellerList;
      newInitialData.options = options;

      Object.assign(state, newInitialData);

      localStorage.setItem(`shop_${window.SITE_ID}`, '');
    },
    setOptionAction: (state, action: PayloadAction<ISetOptionAction>) => {
      const { key, value } = action.payload;
      state.options[key] = value;
    },
    clearOptionAction: (state) => {
      state.selectedImages = [];
      state.selectedProductID = '';
      state.cartSelectedProductID = '';
      state.isEditMode = false;
    },
    setCropImageAction: (state, action: PayloadAction<ISetCropAction>) => {
      const { imageID, cropData } = action.payload;

      if (cropData === null) {
        delete state.croppedImages[imageID];
      } else {
        state.croppedImages[imageID] = { ...state.croppedImages[imageID], ...cropData };
      }
    },
    setCropImagesAction: (state, action: PayloadAction<IDictionary<ICropData>>) => {
      state.croppedImages = action.payload;
    },
    deleteCroppedImageAction: (state, action: PayloadAction<string>) => {
      delete state.croppedImages[action.payload];
    },
    addCroppedImageCountAction: (state, action: PayloadAction<IChangeImageCountAction>) => {
      if (state.croppedImages[action.payload.imageID]) {
        // @ts-ignore
        state.croppedImages[action.payload.imageID].quantity = action.payload.quantity;
      }
    },
    setShopEditModeAction: (state, action: PayloadAction<ISetShopEditMode>) => {
      // @ts-ignore
      state.isEditMode = action.payload.isEditMode;
      if (action.payload.productID) {
        state.selectedProductID = action.payload.productID;
      }
      if (action.payload.cartSelectedProductID) {
        state.cartSelectedProductID = action.payload.cartSelectedProductID;
      }
    },
    updateShopDataAction: (state, action: PayloadAction<Partial<IShopReducer>>) => {
      Object.assign(state, action.payload);
    },
    setCropBoxImageData: (state, action: PayloadAction<ISetCropBoxImageData>) => {
      const { imageID, data } = action.payload;
      if (!state.croppedImages[imageID]) {
        // @ts-ignore
        state.croppedImages[imageID] = { cropBoxData: data };
      } else {
        // @ts-ignore
        state.croppedImages[imageID].cropBoxData = data;
      }
    },
    fetchGlobalShopSettingsForCollectionActionSuccess: (
      state,
      action: PayloadAction<IGlobalShopSettings>
    ) => {
      state.globalShopSettings = action.payload;
    },
    fetchLaboratoryActionSuccess: (state, action: PayloadAction<ILaboratory>) => {
      state.laboratory = action.payload;
    },
    fetchPackagesForUpsellingSuccess: (state, action: PayloadAction<IPackage[]>) => {
      state.upselling.packages = action.payload;
      state.upselling.fetchingPackages = false;
    },
    startFetchingPackagesForUpselling: (state) => {
      state.upselling.fetchingPackages = true;
    },
    stopFetchingPackagesForUpselling: (state) => {
      state.upselling.fetchingPackages = false;
    },
    fetchDigitalPricingForUpsellingSuccess: (state, action: PayloadAction<IDigitalPricingList>) => {
      state.upselling.digitalPricingList = action.payload;
    },
    setGroupedImages: (state, action: PayloadAction<IGroupedImages>) => {
      state.groupedImages = action.payload;
    },
    setGroupedByGalleriesImages: (state, action: PayloadAction<IGroupedByGalleriesImages>) => {
      state.groupedByGalleriesImages = action.payload;
    },
    fetchProductRecListSuccess: (state, action: PayloadAction<IProductRecList>) => {
      state.productRecList = action.payload;
    },
    fetchProductBestsellerListSuccess: (state, action: PayloadAction<IProductRecList>) => {
      state.productBestsellerList = action.payload;
    },
    deleteCroppedImageSuccessAction: (state, action: PayloadAction<IDictionary<ICropData>>) => {
      state.croppedImages = action.payload;
    },
    resetLoginWithPasswordFlow: (state) => {
      state.loginFlowStatus = cloneDeep(initialData.loginFlowStatus);
    },
    resetSignupWithPasswordFlow: (state) => {
      state.createAccountWithPasswordFlowStatus = cloneDeep(
        initialData.createAccountWithPasswordFlowStatus
      );
    },
    setCreateAccountWithPasswordFlowStatus: (state, action) => {
      state.createAccountWithPasswordFlowStatus = {
        ...state.createAccountWithPasswordFlowStatus,
        ...action.payload
      };
    },
    setEndCustomerEmailWasNotFound: (state, action: PayloadAction<boolean>) => {
      state.loginFlowStatus.emailDoesNotExists = action.payload;
    },
    setEndCustomerHasPassword: (state, action: PayloadAction<boolean | null>) => {
      state.loginFlowStatus.endCustomerHasPassword = action.payload;
    },
    setForgotPasswordFlowStatus: (state, action) => {
      state.forgotPasswordFlowStatus = {
        ...state.forgotPasswordFlowStatus,
        ...action.payload
      };
    },
    setLoginFlowStatus: (state, action) => {
      state.loginFlowStatus = {
        ...state.loginFlowStatus,
        ...action.payload
      };
    },
    toggleEnterPasswordModalAction: (state) => {
      state.loginFlowStatus.showEnterPasswordModal = !state.loginFlowStatus.showEnterPasswordModal;
    },
    toggleLoginWithPasswordModalAction: (state) => {
      state.loginFlowStatus.showLoginWithPasswordModal =
        !state.loginFlowStatus.showLoginWithPasswordModal;
    },
    setEmailForLoginWithPassword: (state, action) => {
      state.loginFlowStatus.email = action.payload;
    },
    setPasswordForLoginWithPassword: (state, action) => {
      state.loginFlowStatus.password = action.payload;
    },
    changeGalleryToggleStateAction: (
      state,
      action: PayloadAction<{ galleryID: string; state: boolean }>
    ) => {
      state.galleriesToggleStates[action.payload.galleryID] = action.payload.state;
    }
  }
});

export const {
  setSelectedProductIDAction,
  setSelectedImagesSuccessAction,
  clearShopAction,
  setOptionAction,
  clearOptionAction,
  setCropImageAction,
  setCropImagesAction,
  deleteCroppedImageAction,
  addCroppedImageCountAction,
  setShopEditModeAction,
  updateShopDataAction,
  setCropBoxImageData,
  fetchGlobalShopSettingsForCollectionActionSuccess,
  fetchLaboratoryActionSuccess,
  fetchPackagesForUpsellingSuccess,
  startFetchingPackagesForUpselling,
  stopFetchingPackagesForUpselling,
  fetchDigitalPricingForUpsellingSuccess,
  setGroupedImages,
  setGroupedByGalleriesImages,
  fetchProductRecListSuccess,
  fetchProductBestsellerListSuccess,
  deleteCroppedImageSuccessAction,
  resetLoginWithPasswordFlow,
  resetSignupWithPasswordFlow,
  setCreateAccountWithPasswordFlowStatus,
  setEndCustomerEmailWasNotFound,
  setEndCustomerHasPassword,
  setForgotPasswordFlowStatus,
  setLoginFlowStatus,
  toggleEnterPasswordModalAction,
  toggleLoginWithPasswordModalAction,
  setEmailForLoginWithPassword,
  setPasswordForLoginWithPassword,
  changeGalleryToggleStateAction
} = shopSlice.actions;

export const shopReducer = shopSlice.reducer;
