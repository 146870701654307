import { useEffect, useRef, useState, useMemo } from 'react';
import { setSectionTitleAction } from 'modules/ScrShop/store/actions';
import { gcd, trackProducts } from 'old-store/utils/helpers';
import { IDictionary, IProduct } from 'modules/ScrShop/store/types';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { getProductGroupBySlug, getProductBySlug } from 'modules/ScrShop/store/selectors';
import { convertToGroupedSlug } from 'modules/ScrShop/helpers/slug';
import { getTranslationKey } from 'helpers/texting';
import { IProductGroup } from 'modules/ScrShopNew/store/slices/products/types';

interface IParams {
  groupSlug: string;
  productSlug: string;
  [key: string]: string;
}

interface IProductDetails {
  product?: IProduct;
  selections?: Selection[];
}

export const useSectionTitle = (title: string = '') => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(setSectionTitleAction(title));
  }, [dispatch, title]);

  return undefined;
};

export const useMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
    if ('addListener' in mediaMatch) {
      mediaMatch.addListener(handler);
    } else {
      // @ts-ignore
      mediaMatch.addEventListener('change', handler);
    }

    return () => {
      if ('addListener' in mediaMatch) {
        mediaMatch.removeListener(handler);
      } else {
        // @ts-ignore
        mediaMatch.removeEventListener('change', handler);
      }
    };
  });

  return matches;
};

export const useImageAspectRatio = (
  imageWidth: number,
  imageHeight: number,
  productAspectRatioX: number,
  productAspectRatioY: number,
  autoRotateAspectRatio: boolean
) => {
  const GCD = gcd(imageWidth, imageHeight);
  const imgRatio = [imageWidth / GCD, imageHeight / GCD];
  const imgRatioMax = imgRatio.indexOf(Math.max(...imgRatio));
  const productRatio = [productAspectRatioX, productAspectRatioY];
  const productRatioMax = productRatio.indexOf(Math.max(...productRatio));
  const isNeedToRotate = imgRatioMax !== productRatioMax;

  return isNeedToRotate && autoRotateAspectRatio
    ? {
        aspectRatioX: productAspectRatioY,
        aspectRatioY: productAspectRatioX
      }
    : {
        aspectRatioX: productAspectRatioX,
        aspectRatioY: productAspectRatioY
      };
};

export const usePrevious = <T = undefined>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useTrackOnViewListData = (
  data: any[],
  key: string,
  layerData: IDictionary<any>,
  productsCustomData: IDictionary<any>
) => {
  const [isTracked, setIsTracked] = useState(false);
  const firstProductSlug = get(data, '[0].slug', '');

  useEffect(() => {
    if (!isTracked && data.length && firstProductSlug.length) {
      trackProducts(data, key, layerData, productsCustomData);
      setIsTracked(true);
    }
  }, [data]);
};

type Selection = {
  label: string;
  options: Array<{ name: string; value: string }>;
  value: string;
  change: (value: string) => void;
};

const sortBySize = (a: IProduct, b: IProduct) => {
  const convertSlugSize = (slug: string) =>
    slug
      .split('-')
      .find((part) => part.includes('x'))
      ?.replace('x', '');

  return Number(convertSlugSize(a.slug)) - Number(convertSlugSize(b.slug));
};

const transformToNameAndValue = ({ title, slug, dimensions: { width, height, unit } }) => {
  const name = `${title} ${width}x${height}${unit || ''}`;

  return {
    name,
    value: slug
  };
};

const getSlugType = (slug: string = '') => slug.split('-').slice(2).join('-');

export const useGroupedDetails = () => {
  const { groupSlug = '', productSlug = '' } = useParams<IParams>();

  const groupSlugItem = useAppSelector(getProductGroupBySlug)(groupSlug) as IProductGroup;
  const productItem = useAppSelector(getProductBySlug)(productSlug);
  const [product, setProduct] = useState<IProduct>();

  const currentProductGroup =
    groupSlugItem?.products.filter(({ slug }) =>
      slug.startsWith(convertToGroupedSlug(product?.slug || productSlug))
    ) || [];

  useEffect(() => {
    if (productItem && !product) {
      setProduct(productItem);
    }
  }, [productItem]);

  const getPhotobookProductGroup = () => {
    const currenSelectionItems = currentProductGroup.filter(
      (currentGroupProduct) => getSlugType(currentGroupProduct.slug) === getSlugType(product?.slug)
    );

    const allPossibleSelectionItems = currentProductGroup.filter(
      (product) =>
        currentProductGroup.find(
          (otherProduct) =>
            otherProduct.dimensions.width === product.dimensions.width &&
            otherProduct.dimensions.height === product.dimensions.height
        )?.slug === product.slug
    );

    const missingItems = allPossibleSelectionItems.filter(
      (item) =>
        !currenSelectionItems.some(
          (selectedItem) =>
            item.dimensions.width === selectedItem.dimensions.width &&
            item.dimensions.height === selectedItem.dimensions.height
        )
    );

    if (missingItems.length) {
      return [...currenSelectionItems, ...missingItems];
    }

    return currenSelectionItems;
  };

  const selections = useMemo(() => {
    const selections: Selection[] = [];
    // photobooks can have multiple products with the same dimensions but different material
    // we need to filter out the same group of slugs
    const productsForSizeSelection =
      groupSlug === 'photobook' ? getPhotobookProductGroup() : currentProductGroup;

    selections.push({
      label: getTranslationKey('size'),
      options: productsForSizeSelection?.sort(sortBySize)?.map(transformToNameAndValue) || [],
      value: product?.slug || '',
      change: (value: string) => {
        const currentProduct = currentProductGroup.find((item) => item.slug === value);
        setProduct(currentProduct);
      }
    });

    if (groupSlug === 'photobook') {
      const availableMaterials = currentProductGroup.filter(
        (otherProduct) =>
          otherProduct.dimensions.width === product?.dimensions?.width &&
          otherProduct.dimensions.height === product?.dimensions?.height
      );

      selections.push({
        label: getTranslationKey('material'),
        options: availableMaterials.sort(sortBySize).map((product) => ({
          name: getTranslationKey(
            'photobook.materials.' + product.slug.split('-').slice(2).join('-')
          ),
          value: product.slug
        })),
        value: product?.slug || '',
        change: (value: string) => {
          const currentProduct = currentProductGroup.find((item) => item.slug === value);
          setProduct(currentProduct);
        }
      });
    }

    return selections;
  }, [currentProductGroup, groupSlug, product]);

  return { product, selections };
};

export const useProductDetails = (): IProductDetails => {
  const { search } = useLocation();
  const { productSlug = '' } = useParams<IParams>();
  const product = useAppSelector(getProductBySlug)(productSlug);

  if (search.includes('grouped')) return useGroupedDetails();

  return { product };
};
