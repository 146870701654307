import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';
import { cloneDeep, get } from 'lodash';

export const getStore = (store: IStore) => cloneDeep(store);
export const getEndCustomerStore = ({ endCustomer }: IStore) => cloneDeep(endCustomer);
export const getGlobalStore = ({ global }: IStore) => cloneDeep(global);
export const getSectionTitleSelector = createSelector(
  getGlobalStore,
  ({ sectionTitle }) => sectionTitle
);
export const getSiteGlobalClassNameSelector = createSelector(
  getGlobalStore,
  ({ siteGlobalClass }) => siteGlobalClass
);
export const getGlobalLoading = createSelector(
  getGlobalStore,
  ({ isGlobalLoading }) => isGlobalLoading
);
export const getCheckoutLoading = createSelector(
  getGlobalStore,
  ({ isCheckoutLoading }) => isCheckoutLoading
);
export const getBackButtonRoute = createSelector(
  getGlobalStore,
  ({ backButtonRoute }) => backButtonRoute
);

export const getModalState = createSelector(
  getGlobalStore,
  (global) => (modal: string) => get(global, modal, false)
);
export const getModalDataState = createSelector(
  getGlobalStore,
  ({ customModalData }) => customModalData
);

export const getErrorsState = (state: IStore) => state.error;
export const getBreadcrumbs = createSelector(getGlobalStore, ({ breadcrumbs }) => breadcrumbs);
