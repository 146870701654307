import styled, { css } from 'styled-components';

const LabelTitle = styled.label`
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  margin-right: auto;
`;

interface IElementWrapperProps {
  isDisabled?: boolean;
  label?: string;
  flexDirection?: string;
}

const getDisabledElementWrapper = ({ isDisabled }: IElementWrapperProps) =>
  isDisabled &&
  css`
    > * {
      opacity: 0.7;
      cursor: not-allowed;
    }
  `;

export const Wrapper = styled.div<IElementWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: ${({ flexDirection }) => flexDirection};
  margin-bottom: 10px;
  input,
  select {
    width: 100%;
  }
  ${getDisabledElementWrapper};
  &.error {
    margin-bottom: 5px;
    flex-direction: column;
    input,
    select {
      border: 1px solid #e50606;
      color: #e50606;
    }
  }
`;
const ErrorWrapper = styled.div`
  color: #e50606;
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  margin-right: auto;
  margin-left: 5px;
`;

interface IProps {
  label?: string;
  className?: string;
  isDisabled?: boolean;
  errorText?: string;
  id?: string;
  flexDirection?: string;
}

export const FormPart: React.FC<IProps> = ({
  errorText,
  label,
  children,
  className = '',
  isDisabled,
  flexDirection = 'column',
  id
}) => (
  <Wrapper
    className={`${className} ${errorText ? 'error' : ''}`}
    isDisabled={isDisabled}
    flexDirection={flexDirection}
  >
    {!!label && <LabelTitle htmlFor={id}>{label}</LabelTitle>}
    {children}
    {!!errorText && <ErrorWrapper data-cy="component-validation-error">{errorText}</ErrorWrapper>}
  </Wrapper>
);
