import { ICheckoutReducer } from 'modules/ScrShop/store/types/checkout';

export const DEFAULT_CHECKOUT_STORE: ICheckoutReducer = {
  createdAt: '',
  currency: 'EUR',
  discountTotal: 0,
  grandTotal: 0,
  grossProductPrice: 0,
  grossShippingPrice: 0,
  productDiscount: 0,
  productPrice: 0,
  productQuantity: 0,
  productTax: 0,
  productTotal: 0,
  products: [],
  shippingDiscount: 0,
  shippingPrice: 0,
  shippingTax: 0,
  shippingTotal: 0,
  status: '',
  taxTotal: 0,
  updatedAt: '',
  _collection: '',
  _id: '',
  _laboratory: '',
  _user: '',
  _endCustomer: '',
  _coupon: '',
  // New
  grandProductGrossPrice: 0,
  grandShippingGrossPrice: 0,
  grandShippingPrice: 0,
  grandProductTax: 0,
  grandTax: 0
};
const validation1 = 'required, max250, onlyLetters';
export const DEFAULT_SHIPPING_FORM_STATE = {
  email: {
    value: '',
    error: '',
    validations: 'required, emailFormat',
    title: 'shop.forms.emailTitle'
  },
  firstName: {
    value: '',
    error: '',
    validations: validation1,
    title: 'shop.forms.firstNameTitle'
  },
  lastName: {
    value: '',
    error: '',
    validations: validation1,
    title: 'shop.forms.lastNameTitle'
  },
  street: {
    value: '',
    error: '',
    validations: validation1,
    title: 'shop.forms.streetTitle'
  },
  streetNumber: {
    value: '',
    error: '',
    validations: 'required, max250, onylAlfaNumericAndSlashes',
    title: 'shop.forms.streetNumberTitle'
  },
  zipCode: {
    value: '',
    error: '',
    validations: 'required, onlyValidZipCode',
    title: 'shop.forms.zipCodeTitle'
  },
  _country: {
    value: '',
    error: '',
    validations: 'required',
    title: 'shop.forms.country'
  },
  city: {
    value: '',
    error: '',
    validations: 'required, onlyLetters',
    title: 'shop.forms.cityTitle'
  },
  gtcAndPrivacyCheckbox: {
    value: false,
    error: '',
    validations: 'termsRequired',
    title: 'shop.forms.required'
  },
  telephoneNumber: {
    value: '',
    error: '',
    validations: 'required',
    title: 'shop.forms.required'
  }
};

export const SHIPPING_FORM_FIELD_LENGTH = 10;
