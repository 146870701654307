import { MultiSelect } from 'react-multi-select-component';
import styled from 'styled-components';

import { ITheme, useTheme } from 'hooks/useTheme';
import { getTranslationKey } from 'helpers/texting';
import { Checkbox } from 'components/Checkbox';
import { useAppSelector } from 'hooks/redux';
import { IoIosArrowDown } from 'react-icons/io';

interface IShareSelection {
  options: Array<any>;
  value: Array<any>;
  onChange: (changedGalleries: any) => void;
}

const SelectItemWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: 15px;
  width: 100%;
  color: ${({ theme }) => theme.colors.modalFontColor};
`;

const MultiSelectStyled = styled(MultiSelect)`
  --rmsc-radius: 0;
  --rmsc-bg: ${({ theme }) => theme.colors.modalBg};
  --rmsc-hover: ${({ theme }) => theme.colors.modalBg};
  --rmsc-selected: ${({ theme }) => theme.colors.modalBg};
  --rmsc-h: 28px;
  *:focus {
    outline: none !important;
  }
`;

const SelectWrapper = styled.div<{ isDarkTemplate: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.colors.modalFontColor};
  .dropdown-container {
    border: 0;
    border-bottom: solid 1px ${({ theme }) => theme.colors.modalFontColor} !important;
    &:focus-within {
      box-shadow: initial !important;
    }
    .dropdown-content {
      .panel-content {
        background: ${({ theme }) => theme.colors.white};
      }
      .select-item {
        background: ${({ theme }) => theme.colors.white};
        span {
          color: ${({ theme }) => theme.colors.secondaryButtonFontColor};
        }
      }
    }
  }
`;

const Label = styled.h6`
  font-weight: bold;
`;

const SelectedValue = styled.div`
  color: ${({ theme }) => theme.colors.modalFontColor};
  font-size: 20px;
`;

const StyledArrow = styled(IoIosArrowDown)<{ theme: ITheme }>`
  position: absolute;
  right: 10px;
  top: 30%;
  cursor: pointer;
  z-index: 2;
`;

const ItemRendereWrap = styled.div`
  background: #fff;
  border: 0;
`;

const ItemRenderer: React.FC<{ item: any }> = ({ item }) => (
  <ItemRendereWrap className={`item-renderer ${item.disabled && 'disabled'}`}>
    <Checkbox
      id={item.option.label}
      label={item.option.label}
      onClick={item.onClick}
      disabled={item.disabled}
      checked={item.checked}
    />
  </ItemRendereWrap>
);

export const MultiSelectItem: React.FC<IShareSelection> = ({ options, value, onChange }) => {
  const { templateName, type } = useAppSelector((state) => state.collection);
  const [theme] = useTheme();

  const isDarkTemplate = templateName === 'dark' && type === 'ScrAppBook2';

  const renderValues = (selected: any) => (
    <SelectedValue theme={theme}>
      {selected.length
        ? selected.map((element: any) => element.label).join(', ')
        : getTranslationKey('downloadModal.selectGallery')}
    </SelectedValue>
  );

  return (
    <SelectItemWrapper theme={theme}>
      <Label>{getTranslationKey('shareView.whichGallery')}</Label>
      <SelectWrapper theme={theme} isDarkTemplate={isDarkTemplate} className="select-wrapper">
        <MultiSelectStyled
          theme={theme}
          hasSelectAll={false}
          disableSearch
          options={options}
          overrideStrings={{
            allItemsAreSelected: getTranslationKey('shareView.allGalleriesSelected'),
            selectSomeItems: getTranslationKey('downloadModal.selectGallery')
          }}
          value={value}
          onChange={onChange}
          labelledBy="Select"
          valueRenderer={renderValues}
          // eslint-disable-next-line
          ItemRenderer={(item: any) => <ItemRenderer item={item} />}
          // eslint-disable-next-line react/no-unstable-nested-components
          ArrowRenderer={() => <StyledArrow />}
          ClearSelectedIcon={() => null}
        />
      </SelectWrapper>
    </SelectItemWrapper>
  );
};
