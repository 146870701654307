import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShippingStore } from 'modules/ScrShop/store/selectors/shipping';
import { getEndCustomerStore } from 'modules/ScrShop/store/selectors';
import { setShippingValueAction } from 'modules/ScrShop/store/actions';
import { chain, omit } from 'lodash';
import { DEFAULT_SHIPPING_FORM_STATE } from 'modules/ScrShop/store/constants';

export const PersistShipping = () => {
  const dispatch = useDispatch();
  const shipping = useSelector(getShippingStore);
  const customer = useSelector(getEndCustomerStore);

  useEffect(() => {
    const shippingValues = chain(customer)
      .pick([
        'email',
        'firstName',
        'lastName',
        'street',
        'streetNumber',
        'zipCode',
        '_country',
        'city',
        'gtcAndPrivacyCheckbox',
        'telephoneNumber'
      ])
      .mapValues((value, key) => ({
        ...DEFAULT_SHIPPING_FORM_STATE[key as keyof typeof DEFAULT_SHIPPING_FORM_STATE],
        value: value === null ? (key !== '_country' ? '' : 'germany') : value, // eslint-disable-line
        error: ''
      }))
      .value();

    // @ts-ignore
    dispatch(setShippingValueAction(shippingValues));
  }, [customer]);

  useEffect(() => {
    const itemsToOmit = ['billingAddress', 'isSameBillingAddress'];
    const shippingValue = omit(shipping, itemsToOmit);

    localStorage.setItem(`shipping_${window.SITE_ID}`, JSON.stringify(shippingValue));
  }, [shipping]);

  return null;
};
