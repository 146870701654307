import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'modules/ScrShopNew/store/slices/global/types';

interface IErrorReducerState {
  text?: string;
  title?: string;
  confirmText?: string;
  closeCallback?: () => void;
}

const initialState: IErrorReducerState = {};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    globalError: (state, action: PayloadAction<IError>) => ({ ...state, ...action.payload }),
    showGlobalError: (state, action: PayloadAction<IError>) => ({ ...state, ...action.payload }),
    clearGlobalError: () => ({})
  }
});

export const { globalError, showGlobalError, clearGlobalError } = errorSlice.actions;

export const errorReducer = errorSlice.reducer;
