import { ICheckoutProduct } from 'modules/ScrShopNew/store/slices/checkout/types';
import { mapValues, omit, some } from 'lodash';

export const updateCheckoutProductInList = (
  productToChange: ICheckoutProduct,
  productList: ICheckoutProduct[]
) =>
  productList.map((product: ICheckoutProduct) =>
    productToChange._id === product._id
      ? {
          ...product,
          ...productToChange
        }
      : product
  );

export const isFormHasError = (form: any, excludeValidationFields: string[] = []) => {
  // Exclude billingAddress, isSameBillingAddress, and any custom excluded fields
  const fieldsToVerify = omit(form, [
    'billingAddress',
    'isSameBillingAddress',
    'companyName',
    ...excludeValidationFields
  ]);

  // Check for errors in first-level fields
  const isErrorInTheForm = some(fieldsToVerify, (field) => field?.error?.length);

  return isErrorInTheForm;
};

export const isBillingFormHasError = (form: any) => {
  const isErrorInBillingAddress = some(
    form.billingAddress?.value || {},
    (field) => field?.error?.length
  );

  return isErrorInBillingAddress;
};

export const getFormValues = (values: any): Record<string, any> => {
  const formValues: Record<string, any> = {};

  mapValues(values, (field, key) => {
    if (key === 'billingAddress' && field?.value) {
      formValues.billingAddress = {};

      Object.entries(field.value).forEach(([nestedKey, nestedField]: [string, any]) => {
        formValues.billingAddress[nestedKey] = nestedField.value;
      });
    } else {
      // Extract values from top-level fields
      formValues[key] = field.value;
    }
  });

  return formValues;
};
