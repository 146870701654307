import { Route, Switch } from 'react-router-dom';
import { DefaultLoader } from 'components/Loaders/DefaultLoader';
import { useShopManager } from 'hooks/useShopManager';
import Loadable from 'react-loadable';

const ShopRouter = Loadable({
  loader: () => import('modules/ScrShop'),
  loading: DefaultLoader
});

const PhotobookRouter = Loadable({
  loader: () => import('modules/ScrPhotobook'),
  loading: DefaultLoader
});

const ShopNewRouter = Loadable({
  loader: () => import('modules/ScrShopNew'),
  loading: DefaultLoader
});

const PortfolioRouter = Loadable({
  loader: () => import('modules/ScrPortfolio'),
  loading: DefaultLoader
});

const ScrappbookAppRouter = Loadable({
  loader: () => import('modules/ScrAppBook'),
  loading: DefaultLoader
});

export const AppModules: React.FC = () => {
  useShopManager();

  return (
    <Switch>
      <Route path="/shop-new" component={ShopNewRouter} />
      <Route path="/shop" component={ShopRouter} />
      <Route path="/photobook" component={PhotobookRouter} />
      <Route path="/p/:id" component={PortfolioRouter} />
      <Route path="*" component={ScrappbookAppRouter} />
    </Switch>
  );
};
