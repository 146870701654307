import {
  HTTPClient as HTTPClientCore,
  IRequestConfig
} from 'modules/ScrShopNew/store/utils/HTTPSClient';
import axios from 'axios';

import { CANCEL } from 'redux-saga';
import {
  IFetchProductsAction,
  IGetProductSuggestionAction
} from 'modules/ScrShopNew/store/slices/products/types';
import { ICreateAccountWithPasswordParams } from 'modules/ScrShopNew/store/slices/shop/types';
import {
  IApplyCouponParams,
  ICapturePayment,
  IConvertToOrderAction,
  ICreatePaymentAction,
  IReFetchPayment
} from 'modules/ScrShopNew/store/slices/orders/types';
import {
  IAddProductToCartAction,
  IChangeCheckoutProductQuantityAction,
  IFetchCartAction,
  IRecalculatePricingAction,
  IRemoveCheckoutProductAction,
  IChangePasswordParams,
  IFetchActiveCartAction,
  ILoginAction,
  IUpdateCreateCustomer,
  IUpdateShoppingCartProductAction
} from 'modules/ScrShopNew/store/slices/checkout/types';
import {
  IPhotobookSpread,
  ISpreadImage,
  IProductDetails,
  PhotobookUpdateType
} from 'modules/ScrPhotobook/store/slices/photobook';
import { TEvent } from 'helpers/analytics';

class HTTPClient extends HTTPClientCore {
  /**
   * Overridden method adds CancelToken symbol, that allow redux-saga'
   * "takeLatest" function to cancel any requests automatically.
   */
  public makeRequest<T = any>(config: IRequestConfig): Promise<T> {
    const source = axios.CancelToken.source();

    const request: any = super.makeRequest({
      ...config,
      cancelToken: source.token
    });

    request[CANCEL] = () => source.cancel();

    return request;
  }
}

const APIClient = new HTTPClient({
  baseURL: window.API_URL,
  withCredentials: true
});

export const Api = {
  Site: {
    get: () => APIClient.get(`/api/collections/${window.SITE_ID}`)
  },
  Products: {
    get: (props: IFetchProductsAction) => APIClient.get('/api/collection/shop/products', props),
    getSuggestion: (props: IGetProductSuggestionAction) =>
      APIClient.get('/api/shopping-carts/product-suggestion', {
        ...props,
        excludeProducts: props.excludeProducts && JSON.stringify(props.excludeProducts)
      })
  },
  Cart: {
    addProduct: (props: IAddProductToCartAction) =>
      APIClient.post('/api/collection/shop/cart/product', props),
    fetchCart: (props: IFetchCartAction) => APIClient.get('/api/collection/shop/cart', props),
    claimCart: (props: IFetchCartAction) =>
      APIClient.post('/api/collection/endCustomer/claimShoppingCart', props),
    deleteProduct: (props: IRemoveCheckoutProductAction) =>
      APIClient.delete('/api/collection/shop/cart/product', props),
    changeProductQuantity: (props: IChangeCheckoutProductQuantityAction) =>
      APIClient.post('/api/collection/shop/cart/product/changeQuantity', props),
    updateShoppingCartProduct: (props: IUpdateShoppingCartProductAction) =>
      APIClient.patch('/api/collection/shop/cart/product', props),
    fetchActiveCart: (props: IFetchActiveCartAction) =>
      APIClient.get('/api/shopping-carts/active-cart', props),
    recalculatePricing: (props: IRecalculatePricingAction) =>
      APIClient.patch(`/api/shopping-carts/${props.cartId}/recalculate-pricing`)
  },
  Auth: {
    updateOrCreateCustomer: (props: IUpdateCreateCustomer) =>
      APIClient.post('/api/collection/endCustomer/upsert', props),
    login: (props: ILoginAction) => APIClient.post('/api/end-customers/signin', props),
    checkPassword: (email: string, collectionId: string) =>
      APIClient.get('/api/end-customers/check-password', {
        email,
        collectionId,
        generatePasswordIfNotExists: true
      }),
    checkEmail: (email: string, collectionId: string) =>
      APIClient.get('/api/end-customers/check-email', {
        email,
        collectionId,
        generatePasswordIfNotExists: true
      }),
    resetPassword: (email: string | null, collectionId: string) =>
      APIClient.get('/api/end-customers/reset-password', {
        email,
        collectionId
      }),
    signin: (props: any) => APIClient.post('/api/end-customers/signin', props),
    signup: (props: ICreateAccountWithPasswordParams) =>
      APIClient.post('/api/end-customers/signup', props),
    changePassword: (props: IChangePasswordParams) =>
      APIClient.post('/api/collection/endCustomer/changePassword', props),
    revokePrivacyCheckBox: () =>
      APIClient.post('/api/collection/endCustomer/revokeGtcAndPrivacyCheckbox', {
        collection_id: window.SITE_ID
      }),
    updateEndCustomer: () =>
      APIClient.get('/api/collection/endCustomer', {
        collection_id: window.SITE_ID
      })
  },
  TempCustomer: {
    createTempCustomer: () =>
      APIClient.post(`/api/end-customers/temp?collection_id=${window.SITE_ID}`),
    setSelection: (props: any) => APIClient.post('/api/collection/selection/current', props),
    createSelection: () =>
      APIClient.put('/api/collection/selection', { collection_id: window.SITE_ID }),
    unsetSelection: (props: any) => APIClient.post('/api/collection/selection/unsetCurrent', props),
    deleteSelection: (selectionId: string) =>
      APIClient.delete(`/api/end-customers/selection/${selectionId}`),
    like: (props: any) => APIClient.put('/api/image/like', props),
    comment: (props: any) => APIClient.put('/api/image/comment', props)
  },
  Selection: {
    createSelection: (props: any, headers: any) =>
      APIClient.put('api/selection/createSelectionRequest', props, headers),
    saveSelection: (props: any) => APIClient.post('/api/collection/selection/save', props),
    updateSelection: (props: any) => APIClient.post('/api/collection/selection/update', props)
  },
  Orders: {
    convertToOrder: (props: IConvertToOrderAction) =>
      APIClient.post('/api/collection/shop/cart/convertToOrder', props),
    createPayment: (props: ICreatePaymentAction) =>
      APIClient.post('/api/collection/shop/order/createPayment', props),
    applyCoupon: (props: IApplyCouponParams) =>
      APIClient.post('/api/collection/shop/cart/applyCoupon', props),
    removeCoupon: (props: Pick<IApplyCouponParams, 'shoppingCart_id'>) =>
      APIClient.delete('/api/shopping-carts/coupon', props),
    reFetchPayment: (props: IReFetchPayment) =>
      APIClient.get('/api/collection/shop/payment/notCompletedPayment', props),
    capturePayment: (props: ICapturePayment) => APIClient.post('/api/shop/capture-payment', props)
  },
  Collection: {
    fetchBannersData: (collectionID: string) =>
      APIClient.get(`/api/collections/${collectionID}/banners-data`)
  },
  Portfolios: {
    fetchPortfolio: () =>
      APIClient.get('/api/portfolios/end-customer/portfolio', { portfolioId: window.SITE_ID }),
    fetchPortfolioCollections: (
      collectionsSkip: number,
      collectionsLimit: number,
      searchQuery: string,
      sortBy: string
    ) =>
      APIClient.get('/api/portfolios/end-customer/portfolio-collections', {
        portfolioId: window.SITE_ID,
        collectionsSkip,
        collectionsLimit,
        searchQuery,
        sortBy
      }),
    verifyPassword: (password: string, redirectUrl: string) =>
      APIClient.post('/api/portfolios/verify-password', {
        portfolioId: window.SITE_ID,
        password,
        redirectUrl
      })
  },
  Photobook: {
    fetchPhotobooks: () => APIClient.get(`/api/photobook?collection_id=${window.SITE_ID}`),
    fetchPhotobook: (id: string) =>
      APIClient.get(`/api/photobook/${id}?collection_id=${window.SITE_ID}`),
    createPhotobook: (props: { title: string; slug: string; _product: string }) =>
      APIClient.post(`/api/photobook?collection_id=${window.SITE_ID}`, props),
    deletePhotobook: (id: string) =>
      APIClient.delete(`/api/photobook/${id}?collection_id=${window.SITE_ID}`),
    updatePhotobookTitle: (id: string, title: string, slug: string) =>
      APIClient.put(`/api/photobook/${id}?collection_id=${window.SITE_ID}`, {
        title,
        slug,
        updateType: PhotobookUpdateType.ChangeTitle
      }),
    updatePhotobookSpreads: (
      id: string,
      title: string,
      slug: string,
      spreads: IPhotobookSpread[],
      updateType: PhotobookUpdateType
    ) =>
      APIClient.put(`/api/photobook/${id}?collection_id=${window.SITE_ID}`, {
        title,
        slug,
        spreads,
        updateType
      }),
    fetchLayouts: (aspectRatioX?: number, aspectRatioY?: number, photobookId?: string) =>
      APIClient.get(
        `/api/photobook/layouts?aspectRatioX=${aspectRatioX || ''}&aspectRatioY=${
          aspectRatioY || ''
        }&photobookId=${photobookId || ''}`
      ),
    updateCover: (id: string, productDetails: { color: string }) =>
      APIClient.put(`/api/photobook/${id}?collection_id=${window.SITE_ID}`, {
        productDetails,
        updateType: PhotobookUpdateType.UpdateCover
      }),
    updateCoverText: (id: string, productDetails: IProductDetails) =>
      APIClient.put(`/api/photobook/${id}?collection_id=${window.SITE_ID}`, {
        productDetails
      }),
    getPhotobookFonts: () => APIClient.get('/api/photobook/fonts'),
    reuploadPhotobook: (
      id: string,
      title: string,
      slug: string,
      spreads: ISpreadImage[],
      productDetails: IProductDetails
    ) =>
      APIClient.put(`/api/photobook/${id}?collection_id=${window.SITE_ID}`, {
        productDetails,
        spreads,
        title,
        slug
      }),
    getPreviewPhotobook: (id: string) => APIClient.get(`/api/photobook/${id}/preview`),
    duplicatePhotobook: (id: string) =>
      APIClient.post(`/api/photobook/duplicate/${id}?collection_id=${window.SITE_ID}`)
  },
  Shop: {
    getGlobalShopSettingsForCollection: (collectionID: string) =>
      APIClient.get(`/api/shop/global-settings-for-collection?collectionId=${collectionID}`),
    getLaboratory: (labId: string) => APIClient.get(`/api/laboratories/${labId}`),
    getDigitalPricingListPackages: (pricingListId: string) =>
      APIClient.get(`/api/digital-pricing-list/${pricingListId}/packages`),
    getDigitalPricingList: (pricingListId: string) =>
      APIClient.get(`/api/digital-pricing-list/${pricingListId}`),
    paymentIsApproved: (orderId: string) =>
      APIClient.get(`/api/shop/payment-is-approved/${orderId}`),
    getRecommendations: () => APIClient.get(`/api/shop/recs/${window.SITE_ID}`),
    getBestsellers: () => APIClient.get(`/api/shop/recs/${window.SITE_ID}?bestsellers=true`)
  },
  Images: {
    hideImage: (imageId: string) =>
      APIClient.put(
        `/api/collections/${window.SITE_ID}/hide-image?imageId=${imageId}&collectionId=${window.SITE_ID}`
      ),
    unHideImage: (imageId: string) =>
      APIClient.put(
        `/api/collections/${window.SITE_ID}/unhide-image?imageId=${imageId}&collectionId=${window.SITE_ID}`
      ),
    getImages: () =>
      APIClient.get(
        `/api/images?shared=${window.location.pathname.includes('share')}&collection_id=${
          window.SITE_ID
        }`
      ),
    getPreviewImages: () =>
      APIClient.get(`/api/images?preview=true&collection_id=${window.SITE_ID}`)
  },
  Slideshow: {
    fetch: (id: string) => APIClient.get(`/api/slideshows/${id}`)
  },
  Templates: {
    getTempalteFonts: () => APIClient.get('/api/collection-templates/template-fonts')
  },
  Analytics: {
    trackEvent: async (event: TEvent, beacon?: boolean): Promise<void> => {
      if (beacon && typeof navigator.sendBeacon === 'function') {
        navigator.sendBeacon('/api/analytics', JSON.stringify(event));
      } else {
        return APIClient.post('/api/analytics', event);
      }
    },
    trackEventBulk: async (events: TEvent[], beacon?: boolean): Promise<void> => {
      if (beacon && typeof navigator.sendBeacon === 'function') {
        navigator.sendBeacon('/api/analytics/bulk', JSON.stringify({ events }));
      } else {
        return APIClient.post('/api/analytics/bulk', { events });
      }
    }
  },
  Countries: {
    get: () => APIClient.get('/api/countries')
  },
  Messages: {
    getForEndCustomer: () =>
      APIClient.get(`/api/message/for-end-customer?collectionId=${window.SITE_ID}`),
    trackMessageView: (id: string) =>
      APIClient.post(`/api/message/${id}/track-view?collectionId=${window.SITE_ID}`)
  }
};

export default Api;
