import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBreadcrumb,
  IModalStateAction,
  IGlobalReducer
} from 'modules/ScrShopNew/store/slices/global/types';

const initialState: IGlobalReducer = {
  sectionTitle: '',
  siteGlobalClass: '',
  isGlobalLoading: false,
  isCheckoutLoading: false,
  backButtonRoute: '',
  isShowTermsModal: false,
  isShowCouponModal: false,
  customModalData: {},
  breadcrumbs: []
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSectionTitleAction(state, action: PayloadAction<string>) {
      state.sectionTitle = action.payload;
    },
    setSiteGlobalClass(state, action: PayloadAction<string>) {
      state.siteGlobalClass = action.payload;
    },
    toggleGlobalLoadingAction(state, action: PayloadAction<boolean>) {
      state.isGlobalLoading = action.payload;
    },
    toggleCheckoutLoadingAction(state, action: PayloadAction<boolean>) {
      state.isCheckoutLoading = action.payload;
    },
    setBackButtonRouteAction(state, action: PayloadAction<string>) {
      state.backButtonRoute = action.payload;
    },
    setModalStateAction(state, action: PayloadAction<IModalStateAction>) {
      const { modal, state: modalState, customData } = action.payload;
      state[modal] = modalState;
      state.customModalData = customData || {};
    },
    setBreadcrumbs(state, action: PayloadAction<IBreadcrumb[]>) {
      state.breadcrumbs = action.payload;
    }
  }
});

export const {
  setSectionTitleAction,
  setSiteGlobalClass,
  toggleGlobalLoadingAction,
  toggleCheckoutLoadingAction,
  setBackButtonRouteAction,
  setModalStateAction,
  setBreadcrumbs
} = globalSlice.actions;

export const globalReducer = globalSlice.reducer;
