export const getThemedInputColor = (color: string) => {
  const newDesigns = ['/shop-new', '/photobook'];
  const isNewDesign = newDesigns.some((design) => window.location.pathname.includes(design));

  return isNewDesign ? '#000' : color;
};

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return [0, 0, 0];

  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
}

export function isHexColorDark(hex: string): boolean {
  const [r, g, b] = hexToRgb(hex);

  // Calculate perceived brightness
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance < 0.5; // Dark if luminance is below 0.5
}

export function isRgbColorDark(rgb: string): boolean {
  const match = rgb.match(/^rgb(a?)\((\d+),\s*(\d+),\s*(\d+)/i);

  if (!match) return false; // Invalid format

  const r = parseInt(match[2], 10);
  const g = parseInt(match[3], 10);
  const b = parseInt(match[4], 10);

  // Calculate perceived brightness
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance < 0.5; // Dark if luminance is below 0.5
}

export function getEffectiveBgColor(element: HTMLElement | null): string {
  const rootElement = document.documentElement;

  while (element && element !== rootElement) {
    const bgColor = window.getComputedStyle(element).backgroundColor;

    // Check if it's a valid color (not 'transparent' or 'rgba(0, 0, 0, 0)')
    if (bgColor && bgColor !== 'transparent' && bgColor !== 'rgba(0, 0, 0, 0)') {
      return bgColor;
    }

    // Move to the parent element
    // eslint-disable-next-line no-param-reassign
    element = element.parentElement;
  }

  // Finally, check the <html> background color
  return window.getComputedStyle(document.documentElement).backgroundColor || '#fff';
}
