import { ReactComponent as CloseIcon } from 'assets/images/stroked-close-icon.svg';
import { Button } from 'modules/ScrShopNew/components/Buttons';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import styled from 'styled-components';

export const StyledModalHeader = styled(ModalHeader)`
  &&&& {
    border: 0;
    position: relative;
    padding: 15px 30px 20px !important;
    font-family: 'Noto Sans', sans-serif;

    .modal-title {
      font-size: 30px;
      margin-right: 40px;
      font-weight: 300 !important;
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)<{ topRight?: boolean }>`
  position: absolute;
  right: ${({ topRight }) => (topRight ? '10px' : '30px')};
  top: ${({ topRight }) => (topRight ? '10px' : '50%')};
  transform: ${({ topRight }) => (topRight ? '0' : 'translateY(-50%)')};
  cursor: pointer;
`;

export const StyledModalBody = styled(ModalBody)`
  padding: 0 30px 20px 30px;
  font-family: 'Noto Sans', sans-serif;
`;

export const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  border: 0;
  padding: 0 30px 20px 30px;
  font-family: 'Noto Sans', sans-serif;
`;

export const StyledModalButton = styled(Button)`
  letter-spacing: 0.64px;
  font-family: 'Noto Sans', sans-serif;
`;
