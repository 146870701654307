import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ModalBody } from 'components/Modal/ModalBody';
import { getTranslationKey } from 'helpers/texting';
import { toggleDiscountModal } from 'store/slices/modals';
import { getSliderImages } from 'helpers/sliderImages';
import { IoCloseOutline } from 'react-icons/io5';
import { MdAccessAlarm } from 'react-icons/md';
import { useCurrency } from 'hooks/useCurrency';
import { displayProductPrice } from 'modules/ScrShop/store/utils';
import { trackEventAction } from 'old-store/actions';
import { useSelector } from 'react-redux';
import {
  getProductByID,
  getProductGroupByID,
  isShopActiveSelector
} from 'modules/ScrShop/store/selectors';
import { getCheckoutStore } from 'modules/ScrShop/store/selectors/checkout';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';
import get from 'lodash/get';
import moment from 'moment';

import { theme } from 'assets/css/theme';
import styled from 'styled-components';
import media from 'assets/css/media';

const StyledModal = styled(Modal)`
  font-family: ${theme.font.shop} !important;
  font-weight: 300 !important;
  background: #fff !important;
  color: #000 !important;
  max-width: 715px;
  max-height: 420px;
  min-height: initial;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  h3 {
    font-family: ${theme.font.shop} !important;
    font-weight: 300 !important;
  }

  .modal-content {
    height: 100%;
    background: #fff;
    .close {
      background: #000;
    }
  }
  .modal-body {
    height: 100%;
    background: #fff !important;
    padding: 0;
    color: #000;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  height: 100%;
`;

const ImageContent = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const DiscountContent = styled.div`
  width: 50%;
  padding-left: 40px;
  font-size: 14px;
  letter-spacing: 0.64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 40px;
  @media all and (max-width: ${media.mobile}) {
    font-size: 12px;
    letter-spacing: initial;
    padding: 20px;
  }
`;

const CountDown = styled.span`
  display: flex;
  align-items: center;
  color: #9b9a9a;
  font-size: 16px;
  @media all and (max-width: ${media.mobile}) {
    font-size: 12px;
  }
`;

const StyledAlarmIcon = styled(MdAccessAlarm)`
  &&&& {
    color: #9b9a9a !important;
    margin-right: 10px;
  }
`;

const SaveUpTitle = styled.h3`
  font-size: 40px !important;
  margin: 15px 0;

  @media all and (max-width: ${media.mobile}) {
    font-size: 20px !important;
    margin: 10px 0;
  }
`;

const DiscountDescription = styled.p`
  line-height: 27px;
  font-size: 16px;
  margin: 0;

  @media all and (max-width: ${media.mobile}) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const DiscountButton = styled.button`
  margin-top: 15px;
  background: #000;
  color: #fff;
  padding: 10px 0;
  width: 100%;
  font-size: 16px;
  border: 0;
  @media all and (max-width: ${media.mobile}) {
    font-size: 14px;
  }
`;

const CloseIcon = styled(IoCloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 28px;
  height: 28px;
`;

export const DiscountModal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { discountModal } = useAppSelector((state) => state.modals);
  const { couponCodeAdvertising, freeShipping } = useAppSelector((state) => state.banners);
  const { images } = useAppSelector((state) => state.images);
  const sliderImages = getSliderImages(images);
  const today = moment();
  const endsAt = get(couponCodeAdvertising, 'endsAt', '');
  const validDateMoment = moment(endsAt);
  const daysLeft = validDateMoment.diff(today, 'days');
  const isShopActive = useSelector(isShopActiveSelector);
  const { grandProductGrossPrice } = useSelector(getCheckoutStore);
  const minimumOrderValue = get(couponCodeAdvertising, 'coupon.minimumOrderValue', null);
  const currency = useCurrency();

  const isGrandProductGrossPricePresented = typeof grandProductGrossPrice === 'number';
  const isShowFreeShipping = isShopActive && freeShipping && isGrandProductGrossPricePresented;

  const discount = get(couponCodeAdvertising, 'coupon.discounts[0]', {}) as any;

  const productGroup = useSelector(getProductGroupByID)(discount.productGroupId);
  const product = useSelector(getProductByID)(discount.productId);

  const couponAmount =
    discount.valueType === 'percent'
      ? `${discount.valuePercent}%`
      : displayProductPrice(discount.valueFixed || 0, currency);

  const toggleModal = () => dispatch(toggleDiscountModal());

  const goToShopPage = () => {
    toggleModal();

    if (discount.type === 'productGroup' && discount.productGroupId) {
      dispatch(
        trackEventAction({
          name: 'clicked-action',
          payload: {
            button_id: 'promo-banner',
            is_coupon_code_advertising: couponCodeAdvertising,
            is_free_shipping: isShowFreeShipping,
            location: 'shop',
            placement: 'modal'
          }
        })
      );

      history.push(`/shop-new/products/${discount.productGroupId}`);

      return;
    }

    if (discount.type === 'product' && discount.productId) {
      dispatch(
        trackEventAction({
          name: 'clicked-action',
          payload: {
            button_id: 'promo-banner',
            is_coupon_code_advertising: couponCodeAdvertising,
            is_free_shipping: isShowFreeShipping,
            location: 'shop',
            placement: 'modal'
          }
        })
      );

      history.push(`/shop-new/products/${product?._productGroup}/${product?.slug}?grouped`);
    }
  };

  const getProductTitle = () => {
    const productTitle = get(product, 'title', '');
    const productDimensions = get(product, 'dimensions', null);
    const productCouponTitle = `${productTitle}${
      productDimensions ? ` ${productDimensions.width}x${productDimensions.height}` : ''
    }`;

    const productGroupTitle = get(productGroup, 'title', '');
    const groupTitle = get(productGroup, 'couponTitle', productGroupTitle);

    if (discount.type === 'productGroup') return groupTitle;
    if (discount.type === 'product') return productCouponTitle;

    return getTranslationKey('banners.products');
  };

  const getSaleTranslationVariables = () => {
    const isPercentDiscount = discount.valueType === 'percent';
    const isProductDiscount = discount.type === 'product';
    const productPrice = displayProductPrice(discount.valueFixed || 0, currency);

    return {
      'valuePercent/valueFixed': isPercentDiscount ? discount.valuePercent : productPrice,
      'currency/%': isPercentDiscount ? '%' : '',
      all: isProductDiscount ? '' : ` ${getTranslationKey('banners.all')}`,
      'products/productGroupName': getProductTitle()
    };
  };

  const getSaleMinimumValue = () =>
    typeof minimumOrderValue === 'number'
      ? displayProductPrice(minimumOrderValue * 100, currency)
      : '';

  return (
    <StyledModal
      centered
      isOpen={discountModal.isOpen}
      toggle={toggleModal}
      zIndex={999999}
      size="md"
    >
      <ModalBody>
        <ContentWrap>
          <ImageContent>
            <img src={sliderImages[0]?.url} alt="" />
          </ImageContent>
          <DiscountContent>
            <CountDown>
              {!!daysLeft && (
                <>
                  <StyledAlarmIcon />
                  <span>
                    {daysLeft}{' '}
                    {daysLeft === 1
                      ? getTranslationKey('banners.dayLeft').toUpperCase()
                      : getTranslationKey('banners.daysLeft').toUpperCase()}
                  </span>
                </>
              )}
            </CountDown>
            <SaveUpTitle>
              {getTranslationKey('newShop.saveUpNow', { discount: couponAmount })}
            </SaveUpTitle>
            <DiscountDescription>
              <span>{getTranslationKey('banners.largeBannerShopRow1')}</span>
              <div>
                <span>
                  {getTranslationKey(
                    'banners.largeBannerGalleryRow2',
                    getSaleTranslationVariables()
                  )}
                </span>
                <span> </span>
                {!!getSaleMinimumValue() && (
                  <span>
                    {getTranslationKey('banners.largeBannerGalleryRow2MinimumValue', {
                      minimumValue: getSaleMinimumValue()
                    })}
                  </span>
                )}
              </div>
            </DiscountDescription>

            <DiscountButton onClick={goToShopPage}>
              {getTranslationKey('newShop.discoverNow')}
            </DiscountButton>
          </DiscountContent>
        </ContentWrap>

        <CloseIcon onClick={toggleModal} />
      </ModalBody>
    </StyledModal>
  );
};
