import { useEffect, useRef } from 'react';
import { get } from 'lodash';
import { IOption } from 'modules/ScrShop/store/types';
import sanitizeHtml from 'sanitize-html';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px 0;
  -webkit-overflow-scrolling: touch;
`;
const InnerWrapper = styled.div`
  display: flex;
`;
const ColorOptionItem = styled.div<{ isSelected: boolean }>`
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  margin: 0 5px;
  flex: 0 0 auto;
`;
const ItemInner = styled.div`
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  max-width: 100%;
  position: relative;
  box-shadow: 0 0 10px 1px rgb(160 160 160 / 53%);
  cursor: pointer;
  text-align: center;
`;

const Value = styled.div<{ gradient: string[] }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #000;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    ${(props) => props.gradient[0]} 48%,
    ${(props) => (props.gradient[1] ? `${props.gradient[1]} 51%` : `${props.gradient[0]} 100%`)}
  );
  transform: rotate(45deg);
  cursor: pointer;
  margin: 0 auto;
`;

const ValueImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #000;
  overflow: hidden;
  cursor: pointer;
  margin: 0 auto;
`;

const Label = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

interface ISelectOption {
  value: string;
  text: string;
  option: IOption;
}

interface IProps {
  readonly options: ISelectOption[];
  readonly name: string;
  readonly formValue: string;
  readonly onChange: (e: React.SyntheticEvent) => void;
}

export const ColorSelectOptions: React.FC<IProps> = ({ options, name, onChange, formValue }) => {
  const selectedRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const getIsSelected = (optionValue: string) => optionValue === formValue;
  const getColorOption = (option: ISelectOption): string[] => {
    const colorCombination = get(option, 'option.colorCombination', []);
    const color = get(option, 'option.color', '#fff');

    return colorCombination.length ? colorCombination : [color];
  };
  const getRef = (optionValue: string) => (optionValue === formValue ? selectedRef : null);
  useEffect(() => {
    if (selectedRef.current && containerRef.current) {
      containerRef.current.scrollLeft = selectedRef.current.offsetLeft - 10;
    }
  }, []);

  return (
    <Wrapper ref={containerRef}>
      <InnerWrapper>
        {options.map((option) => (
          <ColorOptionItem
            key={option.value}
            ref={getRef(option.value)}
            data-value={option.value}
            data-name={name}
            onClick={onChange}
            isSelected={getIsSelected(option.value)}
          >
            <ItemInner>
              {option.option.image ? (
                <ValueImageWrapper>
                  <img src={option.option.image.src} alt={option.text} />
                </ValueImageWrapper>
              ) : (
                <Value gradient={getColorOption(option)} />
              )}
              <Label dangerouslySetInnerHTML={{ __html: sanitizeHtml(option.text) }} />
            </ItemInner>
          </ColorOptionItem>
        ))}
      </InnerWrapper>
    </Wrapper>
  );
};
