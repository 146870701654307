import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckoutReducer } from 'modules/ScrShopNew/store/slices/checkout/types';

const initialState: ICheckoutReducer = {
  createdAt: '',
  currency: 'EUR',
  discountTotal: 0,
  grandTotal: 0,
  grossProductPrice: 0,
  grossShippingPrice: 0,
  productDiscount: 0,
  productPrice: 0,
  productQuantity: 0,
  productTax: 0,
  productTotal: 0,
  products: [],
  shippingDiscount: 0,
  shippingPrice: 0,
  shippingTax: 0,
  shippingTaxRate: 0,
  shippingTotal: 0,
  status: '',
  taxTotal: 0,
  updatedAt: '',
  _collection: '',
  _id: '',
  _laboratory: '',
  _user: '',
  _endCustomer: '',
  _coupon: {
    active: false,
    code: '',
    codeLowerCase: '',
    description: null,
    discounts: [],
    endCustomer: null,
    minimumOrderValue: 0,
    name: '',
    sourceCoupon: null,
    validUntilDate: '',
    _id: '',
    _user: ''
  },
  // New
  grandProductGrossPrice: 0,
  grandShippingGrossPrice: 0,
  grandShippingPrice: 0,
  grandProductTax: 0,
  grandTax: 0
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    addUpdateProductToCartSuccessAction(state, action: PayloadAction<ICheckoutReducer>) {
      Object.assign(state, action.payload);
    },
    updateCheckoutStoreAction(state, action: PayloadAction<Partial<ICheckoutReducer>>) {
      Object.assign(state, action.payload);
    },
    cleanUpCheckoutAction(state) {
      Object.assign(state, initialState);
    },
    setCollectionAction(state) {
      const persistData = localStorage.getItem(`checkout_${window.SITE_ID}`);
      const persistState = persistData ? JSON.parse(persistData) : initialState;

      return { ...state, ...persistState };
    }
  }
});

export const {
  addUpdateProductToCartSuccessAction,
  updateCheckoutStoreAction,
  cleanUpCheckoutAction,
  setCollectionAction
} = checkoutSlice.actions;

export const checkoutReducer = checkoutSlice.reducer;
