import { useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import { FormPart } from './index';
import { useTheme } from 'hooks/useTheme';
import { useToggle } from 'hooks/useToggle';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';

export interface IInputProps {
  onClick?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errorText?: string;
  handleBlur?: (e: FocusEvent) => void;
  ref?: any;
  withEye?: boolean;
  icon?: React.ReactNode;
  type: string;
  maxLength?: number;
}

const InputElement = styled.input<IInputProps>`
  border: 1px solid #dedddd;
  box-shadow: none;
  color: ${(props) => props.theme.colors.inputColor};
  background: #fff;
  box-sizing: border-box;
  padding: 0 15px;
  display: inline-flex;
  height: 40px;
  appearance: auto;
  padding-right: ${({ icon }) => (icon ? '30px !important' : '0px')};

  &:disabled {
    opacity: 0.7;
  }

  &:focus-visible {
    outline: none;
    border-color: #212020;
  }

  &::placeholder {
    color: #9b9a9a;
    font-weight: 400;
    opacity: 0.7;
  }

  &[type='checkbox'] {
    min-width: auto;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill:hover {
    background-color: inherit !important;
    background-clip: content-box;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: ${(props) => props.theme.colors.inputColor};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const EyeWrapper = styled.div<{ color: string }>`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    width: 20px;
    height: 20px;
    color: ${({ color }) => color};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    width: 20px;
    height: 20px;
  }
`;

type IProps = React.InputHTMLAttributes<HTMLInputElement> & IInputProps;

export const Input: React.FC<IProps> = forwardRef(
  ({ id, label, errorText, className, withEye, type, icon, ...props }, ref?: any) => {
    const [theme] = useTheme();
    const { toggle: isPasswordShowed, handleToggle: togglePasswordShowing } = useToggle();

    useEffect(() => ref?.current && ref?.current?.focus(), []);

    const eyePasswordType = isPasswordShowed ? 'text' : 'password';

    return (
      <FormPart
        id={id}
        label={label}
        className={className}
        isDisabled={props.disabled}
        errorText={errorText}
      >
        <InputWrapper>
          <InputElement
            id={id}
            ref={ref}
            theme={theme}
            className="input-element"
            type={withEye ? eyePasswordType : type}
            icon={icon}
            {...props}
          />
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {withEye && (
            <EyeWrapper color={theme.colors.inputColor} onClick={togglePasswordShowing}>
              {isPasswordShowed ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </EyeWrapper>
          )}
        </InputWrapper>
      </FormPart>
    );
  }
);
