import { IStore } from 'old-store/types';
import { createSelector } from 'reselect';
import { cloneDeep, filter, find, get, omit } from 'lodash';
import { IShopReducer } from './types';
import {
  getPurchasedGalleriesIds,
  getGalleriesIds
} from 'modules/ScrShopNew/store/slices/galleries/selectors';

export const getCollectionStore = (state) => cloneDeep(state.collection);
export const getGalleriesSelector = createSelector(
  getCollectionStore,
  ({ galleries }) => galleries
);
export const getImagesStore = (state) => cloneDeep(state.images);
export const getImagesSelector = createSelector(getImagesStore, ({ images }) => images);
export const getImageByIDSelector = createSelector(
  getImagesSelector,
  (images) => (imageID: string) => find(images, { _id: imageID })
);
export const getImagesByIdsSelector = createSelector(
  getImagesSelector,
  (images) => (imageIds: string[]) =>
    images
      .filter((image) => imageIds.includes(image._id))
      .sort((a, b) => imageIds.indexOf(a._id) - imageIds.indexOf(b._id))
);
export const getImagesByGalleryId = createSelector(
  getImagesSelector,
  (images) => (galleryID: string) => filter(images, { gallery_id: galleryID })
);

export const getShopStore = ({ shop }: IStore) => shop;
export const getShopStoreWithoutImages = createSelector(getShopStore, (shop) =>
  omit(shop, ['groupedImages', 'groupedByGalleriesImages'])
);
export const getSelectedProductIDSelector = createSelector(
  getShopStore,
  ({ selectedProductID }) => selectedProductID
);
export const getCartSelectedProductIDSelector = createSelector(
  getShopStore,
  ({ cartSelectedProductID }) => cartSelectedProductID
);
export const getSelectedImagesIDsSelector = createSelector(
  getShopStore,
  ({ selectedImages }) => selectedImages
);
export const getIsImageSelectionByIDSelector = createSelector(
  getSelectedImagesIDsSelector,
  (imageIDS) => (imageID: string) => imageIDS.includes(imageID)
);
export const getShopOptionSelector = createSelector(getShopStore, ({ options }) => options);
export const getShopCroppedImagesSelector = createSelector(
  getShopStore,
  ({ croppedImages }) => croppedImages
);
export const getShopCroppedImageByIDSelector = createSelector(
  getShopCroppedImagesSelector,
  (croppedImages) => (imageID: string) => croppedImages[imageID]
);
export const getIsShopEditMode = createSelector(getShopStore, ({ isEditMode }) => isEditMode);

export const getGalleryToggleStateSelector = createSelector(
  getShopStore,
  ({ galleriesToggleStates }) =>
    (galleryID: string) =>
      galleriesToggleStates?.[galleryID]
);

export const isShopActiveSelectorPure = (
  { shopActive, digitalShop }: any,
  { globalShopSettings: { printShopActive } }: IShopReducer
) => (shopActive || get(digitalShop, 'enabled', false)) && printShopActive;

export const isShopActiveSelector = createSelector(
  getCollectionStore,
  getShopStore,
  isShopActiveSelectorPure
);

export const loginFlowStatusSelector = createSelector(
  getShopStore,
  ({ loginFlowStatus }) => loginFlowStatus
);
export const forgotPasswordFlowStatusSelector = createSelector(
  getShopStore,
  ({ forgotPasswordFlowStatus }) => forgotPasswordFlowStatus
);
export const signupWithPasswordFlowStatusSelector = createSelector(
  getShopStore,
  ({ createAccountWithPasswordFlowStatus }) => createAccountWithPasswordFlowStatus
);

export const getLaboratorySelector = createSelector(getShopStore, ({ laboratory }) => laboratory);
export const getUpsellingPackagesSelector = createSelector(
  getShopStore,
  ({ upselling }) => upselling.packages
);
export const getUpsellingDigitalPricingListSelector = createSelector(
  getShopStore,
  ({ upselling }) => upselling.digitalPricingList
);

export const getCurrentGalleriesImages = createSelector(
  getGalleriesIds,
  getImagesSelector,
  (galleriesIds, images) => images.filter((image) => galleriesIds.includes(image.gallery_id))
);

export const getNotPurchasedImages = createSelector(
  getPurchasedGalleriesIds,
  getCurrentGalleriesImages,
  (purchasedGalleriesIds, images) =>
    images.filter((image) => !purchasedGalleriesIds.includes(image.gallery_id))
);

export const getHorizontalImages = createSelector(getCurrentGalleriesImages, (images) =>
  filter(images, (image) => image.width > image.height && image.extension.toLowerCase() !== 'gif')
);

export const getVerticalImages = createSelector(getCurrentGalleriesImages, (images) =>
  filter(images, (image) => image.width < image.height && image.extension.toLowerCase() !== 'gif')
);

export const getSquareImages = createSelector(getCurrentGalleriesImages, (images) =>
  filter(images, (image) => image.width === image.height && image.extension.toLowerCase() !== 'gif')
);

export const getProductRectList = createSelector(
  getShopStore,
  ({ productRecList }) => productRecList
);
export const getProductBestsellerList = createSelector(
  getShopStore,
  ({ productBestsellerList }) => productBestsellerList
);
