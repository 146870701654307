import { theme } from 'assets/css/theme';
import { Navbar } from 'reactstrap';
import { IDictionary } from 'modules/ScrShopNew/store/slices/global/types';

import styled from 'styled-components/macro';
import { get } from 'lodash';
import media from 'assets/css/media';

interface IButton {
  $iconSide?: 'left' | 'right';
  maxWidth?: string;
  disabled?: boolean;
  $size?: 'small' | 'medium' | 'big';
  download?: string;
  href?: string;
}

const sizes: IDictionary<string> = {
  small: '35px',
  medium: '45px',
  big: '50px'
};

export const Button = styled.button<IButton>`
  width: 100%;
  max-width: ${(props) => props.maxWidth || '100%'};
  margin: 0 auto;
  height: ${(props) => sizes[get(props, '$size', 'medium')]};
  background: ${theme.colors.buttonPrimaryBg};
  color: ${theme.colors.buttonPrimaryColor};
  border: 1px solid ${theme.colors.buttonPrimaryBg};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s linear;
  font-weight: 300;

  &:hover {
    background: ${theme.colors.buttonPrimaryColor};
    color: ${theme.colors.buttonPrimaryBg};
    text-decoration: none;
  }

  &:disabled {
    cursor: no-drop;
    background: ${theme.colors.buttonPrimaryDisabledBg};
  }

  svg {
    margin: ${(props) => (props.$iconSide === 'left' ? '0 10px 0 0' : '0 0 0 10px')};
  }

  @media all and (max-width: ${media.mobile}) {
    font-size: 14px;
  }

  @media all and (max-width: ${media.small}) {
    font-size: 11px;
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${theme.colors.buttonSecondaryBg};
  color: ${theme.colors.buttonSecondaryColor};
  font-weight: bold;
  border-color: ${theme.colors.buttonSecondaryBg};

  &:hover {
    background: ${theme.colors.buttonPrimaryBg};
    color: ${theme.colors.buttonPrimaryColor};
  }

  &:disabled {
    background: ${theme.colors.buttonPrimaryDisabledBg};
  }
`;

export const TransparentButton = styled(Button)`
  background: transparent;
  color: ${theme.colors.buttonSecondaryColor};
  font-weight: bold;
  border: 1px solid ${theme.colors.buttonPrimaryBg};

  &:hover {
    background: ${theme.colors.buttonPrimaryBg};
    color: ${theme.colors.buttonPrimaryColor};
  }

  &:disabled {
    background: ${theme.colors.buttonPrimaryDisabledBg};
  }
`;

const StickyButtonStyled = styled(Navbar)<IButton>`
  background: #ffffff;
  width: 100%;
  padding-bottom: 30px;
  z-index: 11;
`;

interface IStickyButton extends IButton {
  onClick: () => void;
  maxWidth?: string;
}

export const StickyButton: React.FC<IStickyButton> = ({ children, ...rest }) => (
  <StickyButtonStyled sticky="bottom">
    <Button {...rest}>{children}</Button>
  </StickyButtonStyled>
);
