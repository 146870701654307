import { ChangeEvent } from 'react';
import { ITheme, useTheme } from 'hooks/useTheme';
import { getTranslationKey } from 'helpers/texting';
import { IoIosArrowDown } from 'react-icons/io';

import styled from 'styled-components';
import media from 'assets/css/media';

interface ISelectionItem {
  withDefaultPlaceholder?: boolean;
  value?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{ name: string; value: string }>;
  label?: string;
}

const SelectItemWrapper = styled.div<{ theme: ITheme }>`
  position: relative;
  display: inline-block;
  padding: 15px;
  width: 100%;

  span {
    font-size: 20px;
  }

  select::-ms-expand {
    display: none;
  }

  select,
  .dropdown-container {
    font-size: 20px;
    width: 100%;
    appearance: none;
    border: none;
    background: none;
    border-bottom: solid 1px grey;
    border-radius: 0;

    @media all and (max-width: ${media.desktopXl}) {
      font-size: 16px;
    }
  }

  select {
    border-bottom: solid 1px ${(props) => props.theme.colors.modalFontColorLight} !important;

    &:focus-visible {
      outline: none;
    }

    color: ${(props) => props.theme.colors.modalFontColor} !important;

    option {
      color: ${(props) => props.theme.colors.modalFontColorLight} !important;
      background-color: ${(props) => props.theme.colors.modalBg} !important;
    }
  }

  color: ${(props) => props.theme.colors.modalFontColorLight};

  .select-wrapper {
    position: relative;
    z-index: 2;
  }
`;

const Label = styled.h6`
  font-weight: bold;
`;

const StyledArrow = styled(IoIosArrowDown)<{ theme: ITheme }>`
  position: absolute;
  right: 25px;
  top: 57%;
  cursor: pointer;
  z-index: 2;
`;

export const SingleSelectItem = ({
  withDefaultPlaceholder,
  value = '',
  onChange,
  options,
  label = '',
  ...props
}: ISelectionItem) => {
  const [theme] = useTheme();

  return (
    <SelectItemWrapper theme={theme} {...props}>
      {label && <Label>{label}</Label>}
      <div className="select-wrapper">
        <select className="cursor-pointer" onChange={onChange} value={value}>
          {withDefaultPlaceholder && (
            <option disabled value="default">
              {getTranslationKey('pleaseSelect')}
            </option>
          )}
          {options.map((item: any) => (
            <option key={item.value} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <StyledArrow />
    </SelectItemWrapper>
  );
};
