import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IProductGroup,
  ProductSuggestion,
  IProductsReducer
} from 'modules/ScrShopNew/store/slices/products/types';

const initialState: IProductsReducer = {
  groups: [],
  isLoading: true,
  suggestions: [],
  isMadeCroppingChanges: false
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProductsAction(state) {
      state.isLoading = true;
    },
    fetchProductsActionSuccess(state, action: PayloadAction<IProductGroup[]>) {
      state.groups = action.payload;
      state.isLoading = false;
    },
    fetchProductSuggestionSuccess(state, action: PayloadAction<ProductSuggestion[]>) {
      state.suggestions = action.payload;
    },
    setMadeCroppingChanges(state, action: PayloadAction<boolean>) {
      state.isMadeCroppingChanges = action.payload;
    }
  }
});

export const {
  fetchProductsAction,
  fetchProductsActionSuccess,
  fetchProductSuggestionSuccess,
  setMadeCroppingChanges
} = productsSlice.actions;

export const productsReducer = productsSlice.reducer;
