import arrowDown from 'assets/images/ChevronDownImage.svg';
import { theme } from 'assets/css/theme';
import { FormPart } from './index';

import styled from 'styled-components';
import media from 'assets/css/media';

const SelectBox = styled.select`
  border: 1px solid #dedddd;
  color: ${theme.colors.primary};
  box-sizing: border-box;
  padding: 0 15px;
  display: inline-flex;
  background-image: url('${arrowDown}');
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 12px;
  height: 40px;
  outline: none;

  -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  @media all and (max-width: ${media.mediumSmall}) {
    background-position: right 3px center;
    background-size: 9px;
    padding: 0 8px;
  }
`;

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  errorText?: string;
}

export const Select: React.FC<IProps> = ({ label, errorText, ...props }) => (
  <FormPart
    isDisabled={props.disabled}
    label={label}
    errorText={errorText}
    className={props.className}
  >
    <SelectBox {...props} />
  </FormPart>
);
