import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShippingReducer } from 'modules/ScrShopNew/store/slices/shipping/types';

const validation1 = 'required, max250, onlyLetters';

interface StringField {
  value: string;
  error: string;
  validations: string;
  title: string;
}

interface BooleanField {
  value: boolean;
  error: string;
  validations: string;
  title: string;
}

export interface IBillingAddress {
  firstName: StringField;
  lastName: StringField;
  street: StringField;
  streetNumber: StringField;
  zipCode: StringField;
  city: StringField;
  _country: StringField;
  companyName: StringField;
  telephoneNumber: StringField;
}

interface IInitialState {
  email: StringField;
  firstName: StringField;
  lastName: StringField;
  street: StringField;
  streetNumber: StringField;
  zipCode: StringField;
  _country: StringField;
  city: StringField;
  gtcAndPrivacyCheckbox: BooleanField;
  telephoneNumber: StringField;
  companyName: StringField;
  isSameBillingAddress: boolean;
  billingAddress: {
    value: IBillingAddress;
    error: string;
    validations: string;
    title: string;
  };
}

export const DEFAULT_SHIPPING_FORM_STATE: IInitialState = {
  email: {
    value: '',
    error: '',
    validations: 'required, emailFormat',
    title: 'shop.forms.emailTitle'
  },
  firstName: {
    value: '',
    error: '',
    validations: validation1,
    title: 'shop.forms.firstNameTitle'
  },
  lastName: {
    value: '',
    error: '',
    validations: validation1,
    title: 'shop.forms.lastNameTitle'
  },
  street: {
    value: '',
    error: '',
    validations: validation1,
    title: 'shop.forms.streetTitle'
  },
  streetNumber: {
    value: '',
    error: '',
    validations: 'required, max250, onylAlfaNumericAndSlashes',
    title: 'shop.forms.streetNumberTitle'
  },
  zipCode: {
    value: '',
    error: '',
    validations: 'required, onlyNumber, onlyValidZipCode',
    title: 'shop.forms.zipCodeTitle'
  },
  _country: {
    value: '',
    error: '',
    validations: 'required',
    title: 'shop.forms.country'
  },
  city: {
    value: '',
    error: '',
    validations: 'required, onlyLetters',
    title: 'shop.forms.cityTitle'
  },
  gtcAndPrivacyCheckbox: {
    value: false,
    error: '',
    validations: 'termsRequired',
    title: 'shop.forms.required'
  },
  telephoneNumber: {
    value: '',
    error: '',
    validations: 'required, onlyNumber',
    title: 'shop.forms.phonePlaceholder'
  },
  companyName: {
    value: '',
    error: '',
    validations: '',
    title: ''
  },
  billingAddress: {
    value: {
      firstName: {
        value: '',
        error: '',
        validations: validation1,
        title: 'shop.forms.firstNameTitle'
      },
      lastName: {
        value: '',
        error: '',
        validations: validation1,
        title: 'shop.forms.lastNameTitle'
      },
      street: {
        value: '',
        error: '',
        validations: validation1,
        title: 'shop.forms.streetTitle'
      },
      streetNumber: {
        value: '',
        error: '',
        validations: 'required, max250, onylAlfaNumericAndSlashes',
        title: 'shop.forms.streetNumberTitle'
      },
      zipCode: {
        value: '',
        error: '',
        validations: 'required, onlyNumber, onlyValidZipCode',
        title: 'shop.forms.zipCodeTitle'
      },
      city: {
        value: '',
        error: '',
        validations: 'required, onlyLetters',
        title: 'shop.forms.cityTitle'
      },
      _country: {
        value: '',
        error: '',
        validations: 'required',
        title: 'shop.forms.country'
      },
      companyName: {
        value: '',
        error: '',
        validations: '',
        title: ''
      },
      telephoneNumber: {
        value: '',
        error: '',
        validations: 'required, onlyNumber',
        title: 'shop.forms.required'
      }
    },
    error: '',
    validations: '',
    title: 'shop.forms.required'
  },
  isSameBillingAddress: true
};

export const SHIPPING_FORM_FIELD_LENGTH = 10;

const initialState: IShippingReducer = DEFAULT_SHIPPING_FORM_STATE;

const shippingSlice = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    setShippingValueAction: (state, action: PayloadAction<Partial<IShippingReducer>>) => ({
      ...state,
      ...action.payload
    })
  }
});

export const { setShippingValueAction } = shippingSlice.actions;

export const shippingReducer = shippingSlice.reducer;
