import { createReducer } from 'redux-act';
import {
  addUpdateProductToCartSuccessAction,
  cleanUpCheckout,
  updateCheckoutStoreAction
} from 'modules/ScrShop/store/actions';
import { setCollection } from 'store/slices/collection';
import { ICheckoutReducer } from 'modules/ScrShop/store/types/checkout';
import { DEFAULT_CHECKOUT_STORE } from '../../constants';

const initialState: ICheckoutReducer = DEFAULT_CHECKOUT_STORE;

export const checkout = createReducer<ICheckoutReducer>({}, initialState);
checkout.on(addUpdateProductToCartSuccessAction, (state, payload) => payload);
checkout.on(updateCheckoutStoreAction, (state, payload) => ({
  ...state,
  ...payload
}));
checkout.on(cleanUpCheckout, () => DEFAULT_CHECKOUT_STORE);
// @ts-ignore
checkout.on(setCollection, (state) => {
  const persistData = localStorage.getItem(`checkout_${window.SITE_ID}`);
  const persistState = persistData ? JSON.parse(persistData) : DEFAULT_CHECKOUT_STORE;

  return {
    ...state,
    ...persistState
  };
});
