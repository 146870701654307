import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICountry } from 'modules/ScrShopNew/store/slices/countries/types';

import { Api } from 'old-store/utils';
import ApiErrors from 'old-store/utils/API/APIErrors';

export const fetchCountries = createAsyncThunk<ICountry[], void>(
  'countries/fetchCountries',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.Countries.get();

      ApiErrors.checkOnApiError(response);

      return response.result;
    } catch (e) {
      console.error(e);

      return rejectWithValue('Failed to fetch countries');
    }
  }
);
