import { IImage } from 'modules/ScrShopNew/store/slices/shop/types';
import { IGallery } from 'old-store/types/gallery';
import shuffle from 'lodash/shuffle';

export const getShuffledImages = (imagesArr: IImage[]) => shuffle(imagesArr);

export const getImageByIdx = (
  imagesArr: IImage[] = [],
  imageIdx: number,
  fallbackImages: IImage[] = [],
  previewImage: IImage | null = null
) =>
  previewImage ||
  imagesArr[imageIdx] ||
  imagesArr[0] ||
  fallbackImages[imageIdx] ||
  fallbackImages[0] ||
  {};

export const getImagesByGalleries = (imagesArr: IImage[], galleries: IGallery[] = []) =>
  galleries.reduce((acc, gallery) => {
    // eslint-disable-next-line
    acc[gallery._id] = imagesArr.filter((image) => image.gallery_id === gallery._id);

    return acc;
  }, {});
