import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import * as shopReducers from 'modules/ScrShop/store/reducers';
import { shopSaga } from 'modules/ScrShop/store/sagas';
import { coreSaga } from 'old-store/sagas';
import { reducers as rtkReducers } from 'store';
import { reducers as rtkShopReducers } from 'modules/ScrShopNew/store';
import { middlewares } from 'store/middlewares';

import * as coreReducers from 'old-store/reducers/index';

const sagaMiddleware = createSagaMiddleware();

const getShopReducers = () => {
  const isOldShop =
    window.location.pathname.endsWith('/shop') || window.location.pathname.includes('/shop/');

  return isOldShop ? shopReducers : rtkShopReducers;
};

const currentShop = getShopReducers();
type CurrentShopType = typeof currentShop;

export const createRootReducer = (shopReducer: CurrentShopType) =>
  combineReducers({
    ...coreReducers,
    ...rtkReducers,
    ...shopReducer
  });

// Initial reducer setup
export const rootReducer = createRootReducer(currentShop);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([sagaMiddleware, ...middlewares])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(coreSaga);
sagaMiddleware.run(shopSaga);
