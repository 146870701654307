import { getCookie } from 'helpers/cookie';
import { compact, filter, omit, values } from 'lodash';
import { getProductTrackingData, trackProducts } from 'old-store/utils';
import { ICheckoutProduct } from 'modules/ScrShopNew/store/slices/checkout/types';
import { IProduct } from 'modules/ScrShopNew/store/slices/products/types';
import Api from 'old-store/utils/API';
import ApiErrors from 'old-store/utils/API/APIErrors';
import TagManager from 'react-gtm-module';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, take } from 'redux-saga/effects';
import {
  addUpdateProductToCartSuccessAction,
  cleanUpCheckout,
  fetchProductSuggestion,
  setCheckOutFlowAction,
  toggleCheckoutLoadingAction,
  updateCheckoutStoreAction
} from 'modules/ScrShop/store/actions';
import {
  getCollectionStore,
  getProductByID,
  getSelectedImagesIDsSelector,
  getShopStore
} from 'modules/ScrShop/store/selectors';
import {
  getCheckoutIDSelector,
  getCheckoutProducts,
  getCheckoutStore
} from 'modules/ScrShop/store/selectors/checkout';
import { ISagaAction } from 'modules/ScrShop/store/types';
import {
  IAddProductToCartAction,
  IChangeCheckoutProductQuantityAction,
  IRecalculatePricingAction,
  IRemoveCheckoutProductAction
} from 'modules/ScrShop/store/types/checkout';
import { selectBanners } from 'store/slices/banners/selectors';
import { setCollection } from 'store/slices/collection';
import { saveEndCustomer } from 'store/slices/endCustomer';
import { toast } from 'react-toastify';
import { HttpStatus } from 'constants/statusCodes';
import { getTranslationKey } from 'helpers/texting';

export const fetchCheckoutSaga = function* (): SagaIterator {
  yield put(toggleCheckoutLoadingAction(true));
  yield take(setCollection);
  yield take(saveEndCustomer);
  const { authed, preAuthed } = yield select((state) => state.endCustomer);
  const { _user } = yield select(getCollectionStore);
  // @ts-ignore
  const cookiesCheckoutID = getCookie(`checkoutID_${window.SITE_ID}`);
  if (!cookiesCheckoutID && !_user) {
    return;
  }
  try {
    const isAnonymous = !(authed || preAuthed);
    const apiMethod = !isAnonymous ? Api.Cart.fetchActiveCart : Api.Cart.fetchCart;
    const data = !isAnonymous
      ? {
          collectionId: window.SITE_ID
        }
      : {
          collection_id: window.SITE_ID,
          shoppingCart_id: cookiesCheckoutID
        };
    // @ts-ignore
    const response = yield call(apiMethod, data);
    ApiErrors.checkOnApiError(response);
    const cart = !isAnonymous ? response.result : response;
    const validStatuses = ['active', 'converted', 'abandoned'];
    if (validStatuses.indexOf(cart.status) > -1) {
      yield put(addUpdateProductToCartSuccessAction(cart));
    } else {
      document.cookie = `checkoutID_${window.SITE_ID}=; Max-Age=-99999999;`;
    }

    yield put(toggleCheckoutLoadingAction(false));
  } catch (e) {
    yield put(toggleCheckoutLoadingAction(false));
    console.log(e);
  }
};

export const claimCheckoutSaga = function* (): SagaIterator {
  const cookiesCheckoutID = getCookie(`checkoutID_${window.SITE_ID}`);
  if (!cookiesCheckoutID) return;

  const { _endCustomer } = yield select(getCheckoutStore);

  if (_endCustomer) {
    const response = yield call(Api.Cart.fetchCart, {
      collection_id: window.SITE_ID,
      shoppingCart_id: cookiesCheckoutID
    });

    if (response.status === 'active') {
      yield put(addUpdateProductToCartSuccessAction(response));
    }

    return;
  }

  const response = yield call(Api.Cart.claimCart, {
    collection_id: window.SITE_ID,
    shoppingCart_id: cookiesCheckoutID
  });
  ApiErrors.checkOnApiError(response);
  if (response.status === 'active') {
    yield put(addUpdateProductToCartSuccessAction(response));
  } else {
    console.warn('Checkout Cookie deleted');
    localStorage.removeItem(`checkout_${window.SITE_ID}`);
    document.cookie = `checkoutID_${window.SITE_ID}=; Max-Age=-99999999;`;
  }
};

const trackAddProduct = (product: ICheckoutProduct | IProduct, options: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            ...getProductTrackingData(product, null, compact(values(options)).join('-'), 1)
          }
        ]
      }
    },
    dataLayerName: window.SHOP_DATA_LAYER_NAME
  });
};

export const addProductToCartSaga = function* ({
  payload
}: ISagaAction<{
  selectionId?: string;
  callback?: () => void;
  photobookId?: string;
} | void>): SagaIterator {
  const { croppedImages, selectedProductID, cartSelectedProductID, options } = yield select(
    getShopStore
  );
  const selectedImages = yield select(getSelectedImagesIDsSelector);
  const product = yield select((state) => getProductByID(state)(selectedProductID));
  const cartID = yield select(getCheckoutIDSelector);
  const images = selectedImages.map((imageId: string, index: number) => {
    const croppedImage = croppedImages[`${product?.slug}-${imageId}`];

    return {
      _image: imageId,
      crop: croppedImage
        ? {
            ...croppedImage,
            _image: imageId
          }
        : null,
      quantity: croppedImage && croppedImage.quantity ? croppedImage.quantity : 1,
      order: index + 1
    };
  });

  let requestData: IAddProductToCartAction = {
    collection_id: window.SITE_ID,
    product_id: selectedProductID,
    images,
    selectedOptions: compact(values(options)),
    ...(payload ? { selectionId: payload.selectionId } : {})
  };

  if (payload?.photobookId) {
    requestData = omit(requestData, 'images', 'selectedOptions');
    requestData.photobook_id = payload.photobookId;
    requestData.images = [];
  }

  if (cartID) {
    requestData.shoppingCart_id = cartID;
  }
  // Indicates if shop in "edit" mode
  if (cartSelectedProductID) {
    requestData.shoppingCartProduct_id = cartSelectedProductID;
    requestData = omit(requestData, 'product_id');
  } else {
    trackAddProduct(product, options);
  }

  if (process.env.NODE_ENV === 'development') {
    console.warn('=============================');
    console.warn('[CUSTOM CONSOLE] Add or update product requests data');
    console.log(requestData);
    console.warn('=============================');
  }

  const currentMethod = !cartSelectedProductID ? 'addProduct' : 'updateShoppingCartProduct';

  try {
    // @ts-ignore
    const response = yield call(Api.Cart[currentMethod], requestData);

    if (payload?.photobookId && response.code === HttpStatus.BadRequest) {
      toast.error(getTranslationKey('photobook.fillTheSpreadsError'), {
        theme: 'colored',
        autoClose: 3000,
        hideProgressBar: true
      });
    }

    ApiErrors.checkOnApiError(response);
    yield put(addUpdateProductToCartSuccessAction(response));

    payload?.callback?.();
  } catch (e) {
    console.log(e);
  }
};

export const deleteProductFormCheckoutSaga = function* ({
  payload
}: ISagaAction<IRemoveCheckoutProductAction>): SagaIterator {
  const products = yield select(getCheckoutProducts);
  const { emptyCartProductSuggestion = { enabled: false } } = yield select(selectBanners);

  if (products.length === 1) {
    if (emptyCartProductSuggestion.enabled === true) {
      yield put(
        fetchProductSuggestion({
          collectionId: window.SITE_ID,
          excludeProducts: products.map((product: ICheckoutProduct) => product._id)
        })
      );
    }
  } else {
    const filteredProducts = filter(products, (product) => product._id !== payload.productID);

    yield put(updateCheckoutStoreAction({ products: filteredProducts }));
  }
  try {
    const response = yield call(Api.Cart.deleteProduct, payload);
    ApiErrors.checkOnApiError(response);
    yield put(updateCheckoutStoreAction(response));

    if (products.length === 1) {
      yield put(cleanUpCheckout());
    }
  } catch (e) {
    console.log(e);
  }
};

export const changeCheckoutProductQuantitySaga = function* ({
  payload
}: ISagaAction<IChangeCheckoutProductQuantityAction>): SagaIterator {
  const products = yield select(getCheckoutProducts);
  let trackProduct = {};
  let initialQuantity = 0;
  if (!payload.quantity) {
    return;
  }
  const updatedProducts = products.map((product: ICheckoutProduct) => {
    const quantity =
      product._id === payload.shoppingCartProduct_id ? payload.quantity : product.quantity;
    if (product._id === payload.shoppingCartProduct_id) {
      trackProduct = product;
      initialQuantity = product.quantity;
    }

    return {
      ...product,
      quantity
    };
  });
  yield put(
    updateCheckoutStoreAction({
      products: updatedProducts
    })
  );
  yield put(
    setCheckOutFlowAction({
      isPricingUpdating: true
    })
  );
  yield delay(1500);
  if (payload.quantity < initialQuantity) {
    trackProducts([trackProduct], 'remove_from_cart', {}, { quantity: payload.quantity });
  }
  try {
    const response = yield call(Api.Cart.changeProductQuantity, payload);
    ApiErrors.checkOnApiError(response);
    yield put(updateCheckoutStoreAction(response));
    yield put(
      setCheckOutFlowAction({
        isPricingUpdating: false
      })
    );
  } catch (e) {
    console.log(e);
  }
};

// eslint-disable-next-line
export const cleanCheckoutSaga = function* (): SagaIterator {
  localStorage.removeItem(`checkout_${window.SITE_ID}`);
  localStorage.removeItem(`orders_${window.SITE_ID}`);
  document.cookie = `checkoutID_${window.SITE_ID}=`;
};

export const recalculatePricingSaga = function* ({
  payload
}: ISagaAction<IRecalculatePricingAction>): SagaIterator {
  try {
    yield put(
      setCheckOutFlowAction({
        isPricingUpdating: true
      })
    );

    const response = yield call(Api.Cart.recalculatePricing, payload);
    ApiErrors.checkOnApiError(response);

    yield put(updateCheckoutStoreAction(response.result));
    yield put(
      setCheckOutFlowAction({
        isPricingUpdating: false
      })
    );
  } catch (e) {
    console.log(e);
  }
};
