import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrder, IOrdersReducer, IPayment } from 'modules/ScrShopNew/store/slices/orders/types';
import { cloneDeep } from 'lodash';

export const ORDER_DEFAULTS: IOrder = {
  billing: {},
  createdAt: '',
  currency: 'EUR',
  delivered: false,
  discountTotal: 0,
  grandProductGrossPrice: 0,
  grandProductPrice: 0,
  grandProductTax: 0,
  grandShippingGrossPrice: 0,
  grandShippingPrice: 0,
  grandShippingTax: 0,
  grandTax: 0,
  grandTotal: 0,
  grossProductPrice: 0,
  grossShippingPrice: 0,
  orderNumber: 0,
  paid: false,
  paymentBalance: 0,
  paymentTotal: 0,
  payments: [{}],
  productDiscount: 0,
  productPrice: 0,
  productQuantity: 0,
  productTax: 0,
  products: [],
  shipping: {},
  shippingDiscount: 0,
  shippingPrice: 0,
  shippingTax: 0,
  status: '',
  taxTotal: 0,
  updatedAt: '',
  _collection: '',
  _endCustomer: '',
  _id: '',
  _laboratory: '',
  _shoppingCart: '',
  _user: ''
};

export const PAYMENT_DEFAULTS: IPayment = {
  paypal: {
    paymentId: ''
  },
  status: '',
  success: false,
  _id: '',
  _endCustomer: '',
  _user: '',
  _order: '',
  _shoppingCart: '',
  amount: 0,
  method: 'paypal',
  async: true,
  authorized: false,
  currency: 'EUR',
  test: true,
  checkoutUrl: '',
  createdAt: '',
  updatedAt: '',
  paymentNumber: 0
};

const initialState: IOrdersReducer = cloneDeep({
  order: ORDER_DEFAULTS,
  orders: [],
  redirectToPayment: false,
  capturingStatus: {
    success: false,
    failure: false,
    downloadLink: ''
  }
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    convertToOrderSuccessAction: (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
    },
    setRedirectToPaymentAction: (state, action: PayloadAction<boolean>) => {
      state.redirectToPayment = action.payload;
    },
    capturePaymentSuccess: (state, action: PayloadAction<string>) => {
      state.capturingStatus = { success: true, failure: false };
      state.capturingStatus.downloadLink = action.payload;
    },
    capturePaymentFailure: (state) => {
      state.capturingStatus = { success: false, failure: true };
    },
    resetCapturePaymentStatus: (state) => {
      state.capturingStatus = { success: false, failure: false };
    },
    cleanUpOrders: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const {
  convertToOrderSuccessAction,
  setRedirectToPaymentAction,
  capturePaymentSuccess,
  capturePaymentFailure,
  resetCapturePaymentStatus,
  cleanUpOrders
} = ordersSlice.actions;

export const ordersReducer = ordersSlice.reducer;
