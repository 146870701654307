import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry, ICountriesReducerState } from 'modules/ScrShopNew/store/slices/countries/types';
import { fetchCountries } from './actions';

// Define the initial state based on the ICountriesReducerState interface
const initialState: ICountriesReducerState = {
  items: [],
  isFetched: false
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.fulfilled, (state, action: PayloadAction<ICountry[]>) => {
        state.items = action.payload;
        state.isFetched = true;
      })
      .addCase(fetchCountries.rejected, (state) => {
        state.isFetched = false;
      });
  }
});

export const countriesReducer = countriesSlice.reducer;
