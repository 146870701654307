import queryString from 'query-string';
import axios from '../utils/axios';
import db from '../../database';
import {
  setCheckPassword,
  setCollection,
  setDownloadToken,
  setCollectionFetchStatus
} from 'store/slices/collection';
import { setUserBrand } from 'store/slices/userBrand';
import { setImages } from 'store/slices/images';
import { Api } from 'old-store/utils/API';
import { fetchBanners } from 'store/slices/banners/actions';

import { HttpStatus } from 'constants/statusCodes';
import {
  groupImagesBySizeType,
  fetchGlobalShopSettingsForCollection
} from 'modules/ScrShopNew/store/slices/shop/actions';
import { fetchProducts } from 'modules/ScrShopNew/store/slices/products/actions';

// old shop version
import {
  groupImagesBySizeType as oldGroupImagesBySizeType,
  fetchGlobalShopSettingsForCollectionAction as oldFetchGlobalShopSettingsForCollectionAction,
  fetchProductsAction as oldFetchProductsAction
} from 'modules/ScrShop/store/actions';

const saveInitialReferrer = () => {
  const currentSiteId = window.location.pathname.split('/')[1];
  const referrer = document?.referrer || window.frames?.top?.document?.referrer;

  window.localStorage.setItem(`initialReferrer-${currentSiteId}`, referrer);
};

export const checkIfSiteChanged = (site) => async (dispatch, getState) => {
  try {
    const { isApp } = getState().environment;
    let pwCorrect;
    if (!isApp) pwCorrect = queryString.parse(window.location.search).pwCorrect;

    const res = await axios.get('/api/site/checkChange', {
      params: {
        updated_at: site.updated_at,
        site_id: site._id,
        pwCorrect
      },
      timeout: 10000
    });

    const { data } = res.result;

    if (res.status === HttpStatus.OK) {
      db.sites.update(site._id, { data }).catch((e) => {
        if (e && e.inner) {
          console.log('DataBaseError checkIfSiteChanged status=200 inner:', e.inner, e);
        } else {
          console.log('DataBaseError checkIfSiteChanged status=200:', e);
        }
      });

      return data;
    }
  } catch (e) {
    if (e.response && e.response.status === HttpStatus.Forbidden) {
      db.sites.delete(site._id).catch((err) => {
        if (err && err.inner) {
          console.log('DataBaseError checkIfSiteChanged status=403:', err.inner, err);
        } else {
          console.log('DataBaseError checkIfSiteChanged status=403:', err);
        }
      });

      dispatch(setCheckPassword(true));

      return undefined;
    }

    // not changed
    if (e.response && e.response.status === HttpStatus.NotModified) return site;
  }
};

export const getDownloadToken = () => async (dispatch, getState) => {
  try {
    const {
      collection: { password },
      environment: { isApp, iosVersion }
    } = getState();

    if ((password && isApp && iosVersion && iosVersion >= 13) || (password && window.API_URL_DL)) {
      const res = await axios.get(`/api/download/downloadToken/${window.SITE_ID}`);

      console.log('res', res);

      dispatch(setDownloadToken(res.data.downloadToken));
    }
  } catch (e) {
    console.log(e);
  }
};

export const checkPassword = (site_id, password) => async () => {
  console.log('NOW WE CHECKING THE PASSWORD');
  try {
    saveInitialReferrer();

    window.location.href = `${
      window.API_URL
    }/api/site/check_password?site_id=${site_id}&password=${encodeURIComponent(
      password
    )}&redirectUrlAfterAuth=${encodeURIComponent(window.location.href)}`;
  } catch (e) {
    console.log(e);
  }
};

export const logStatistics = (data) => (dispatch, getState) => {
  try {
    const {
      environment: { siteTracking }
    } = getState();

    if (!siteTracking) return;

    if (!data.collection_id) data.collection_id = window.SITE_ID; // eslint-disable-line

    axios.put('/api/statistic/collection', data);
  } catch (e) {
    console.log(e);
  }
};

export const getCollectionFields = (fields) => async (dispatch, getState) => {
  try {
    const { environment } = getState();
    const { isApp } = environment;
    const { SITE_ID } = window;
    const fieldsStr = fields.join(',');

    // kann entfernt werden, wenn web app manifest starturl enthält
    let pwCorrect;
    if (!isApp) pwCorrect = queryString.parse(window.location.search).pwCorrect;

    const { data } = await axios.get(`/api/collections/${SITE_ID}`, {
      params: { pwCorrect, fields: fieldsStr }
    });

    dispatch(setCollection(data.result));

    return data.result;
  } catch (e) {
    console.log('e', e);
  }
};

// this can be moved back to the new store once App.js has been refactored
export const getCollectionById = (id) => async (dispatch, getState) => {
  try {
    const { environment, collection: stateCollection } = getState();
    const { isApp, images: imagesData } = environment || {};

    let pwCorrect;
    // kann entfernt werden, wenn web app manifest starturl enthält
    if (!isApp) pwCorrect = queryString.parse(window.location.search).pwCorrect;

    const { data: collection } = await axios.get(`/api/collections/${id}`, {
      params: { pwCorrect }
    });
    if (collection.result.expired && !window.location.href.includes('/expired')) {
      dispatch(setCollection(collection.result));
      window.location.href = `/${collection.result._id}/expired`;

      return;
    }

    // we have to fetch banners before images for the proper layout building
    dispatch(fetchBanners());

    const { pathname } = window.location;

    const isPhotobookPreviewPage = pathname.includes('photobook/preview');
    const isNotSharePage = !pathname.includes('/share/');

    const isPreviewPhotobookPage = isPhotobookPreviewPage && isNotSharePage;

    const imagesMethod = isPreviewPhotobookPage ? 'getPreviewImages' : 'getImages';

    const images = await Api.Images[imagesMethod]();

    // fetch this in an async thunk or RTK query
    const { data: userBrands } = await axios.get(`/api/collections/${id}/userbrand`);

    if (collection.result.galleryAppsIoUrl) {
      window.location.href = collection.result.galleryAppsIoUrl;

      return null;
    }

    // @ts-ignore
    db.sites.put({ id, data: collection.result }).catch((e) => {
      if (e && e.inner) {
        console.log('DataBaseError getSiteById status=200 inner:', e.inner, e);
      } else console.log('DataBaseError getSiteById status=200:', e);
    });

    dispatch(setUserBrand(userBrands.result));

    dispatch(setImages({ images, signedWildCardUrl: collection.result.signedWildCardUrl }));
    dispatch(setCollection(collection.result));

    if (stateCollection?.collectionCreator?.featureFlags?.newShop) {
      dispatch(fetchGlobalShopSettingsForCollection());
      dispatch(fetchProducts({ collection_id: window.SITE_ID }));
      dispatch(groupImagesBySizeType());
    } else {
      dispatch(oldFetchGlobalShopSettingsForCollectionAction());
      dispatch(oldFetchProductsAction({ collection_id: window.SITE_ID }));
      dispatch(oldGroupImagesBySizeType());
    }

    return { collection: collection.result, imagesData, images };
  } catch (e) {
    if (e.response && e.response.status === HttpStatus.Forbidden) {
      // @ts-ignore
      db.sites.delete(id).catch((err) => {
        if (err && err.inner) {
          console.log('DataBaseError getSiteById status=403 inner:', err.inner, err);
        } else {
          console.log('DataBaseError getSiteById status=403:', err);
        }
      });

      dispatch(setCheckPassword(true));
    }
  }

  return null;
};

export const setCollectionFetchedStatus = (fetched) => (dispatch) => {
  try {
    dispatch(setCollectionFetchStatus(fetched));
  } catch (error) {
    console.log(error);
  }
};
