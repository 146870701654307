import { IProduct } from 'modules/ScrShopNew/store/slices/products/types';
import { ICheckoutProduct } from 'modules/ScrShopNew/store/slices/checkout/types';
import { IDictionary } from 'modules/ScrShopNew/store/slices/global/types';
import { IImage } from 'modules/ScrShopNew/store/slices/shop/types';
import { find, get, mapValues, omitBy, isNil, map } from 'lodash';
import TagManager from 'react-gtm-module';
import db from 'database';
import mixpanel from 'mixpanel-browser';
import { clearAllCookies } from 'helpers/cookie';

export const getImageByIDHelper = (imageID: string, images: IImage[]) =>
  find(images, { _id: imageID });

export const isInViewport = (element: HTMLBaseElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));

export const escapeStrForRegex = (input: string) => {
  const source = typeof input === 'string' ? input : '';

  return source.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const replaceValuesInString = (
  str: string,
  replacedValues?: IDictionary<string | number>
) => {
  let stringToReplace = str;

  if (!stringToReplace) return '';

  mapValues(replacedValues, (value: string, replaceKey) => {
    stringToReplace = stringToReplace.replace(`{${replaceKey}}`, value);
  });

  return stringToReplace;
};

export const getProductTrackingData = (
  product: IProduct | ICheckoutProduct,
  index?: number | null,
  itemVariant?: string,
  quantity?: number
) => {
  const productQuantity = get(product, 'quantity', quantity);
  const options = get(product, 'selectedOptions', []).join('-');
  const productOptions = options.length ? options : null;
  const isCartProduct = get(product, '_product', false);
  const priceKey = isCartProduct ? 'grandGrossPrice' : 'priceTotal';
  const productPrice = get(product, priceKey, 0);

  return omitBy(
    {
      item_name: product.slug,
      item_id: product._id,
      price: productPrice / 100,
      item_category: product._productGroup,
      item_list_name: `${product._productGroup}-page`,
      index,
      currency: product.currency,
      item_variant: itemVariant || productOptions,
      quantity: productQuantity
    },
    isNil
  );
};

export const trackProducts = (
  products: any[],
  event: string,
  layerData: IDictionary<any>,
  productsCustomData: IDictionary<any>,
  isReset: boolean = false
) => {
  try {
    const trackList = map(products, (product, index: number) =>
      getProductTrackingData(
        product,
        productsCustomData.productIndex || index,
        productsCustomData.itemVariant,
        productsCustomData.quantity
      )
    );
    TagManager.dataLayer({
      dataLayer: { event, ecommerce: { ...layerData, items: trackList }, _clear: true },
      dataLayerName: window.SHOP_DATA_LAYER_NAME
    });
    if (isReset && window.google_tag_manager) {
      const gtm = window.google_tag_manager['GTM-TZ8N4M8'];
      if (gtm && gtm.dataLayer) {
        gtm.dataLayer.reset();
      }
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const logout = async () => {
  // @ts-ignore
  await db.sites.delete(window.SITE_ID);

  localStorage.clear();

  clearAllCookies([
    `appInstallationState-${window.SITE_ID}`,
    `showHideImageHelper-${window.SITE_ID}`
  ]);

  mixpanel.reset();
  window.location.href = `/${window.SITE_ID}`;
};

export const dispatchCurrentShopAction = (dispatch, args) => (newAction, oldAction) => {
  const isNewShop = window.location.pathname.includes('/shop-new');

  dispatch(isNewShop ? newAction(args) : oldAction(args));
};
