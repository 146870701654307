import { ICheckoutProduct } from 'modules/ScrShop/store/types/checkout';
import { findKey, mapValues } from 'lodash';
import { IDictionary } from 'modules/ScrShop/store/types';

export const updateCheckoutProductInList = (
  productToChange: ICheckoutProduct,
  productList: ICheckoutProduct[]
) =>
  productList.map((product: ICheckoutProduct) =>
    productToChange._id === product._id
      ? {
          ...product,
          ...productToChange
        }
      : product
  );

export const isFormHasError = (form: any) => findKey(form, (formData) => formData?.error?.length);

export const getFormValues = (values: any) => {
  const formValues: IDictionary<string> = {};
  mapValues(values, (field, key) => {
    formValues[key] = field.value;
  });

  return formValues;
};
