export const addCoverSpread = (photobooksItems) => {
  const photobooks = [...photobooksItems];

  const coverSpread = {
    _id: '',
    frameLayout: '',
    images: [],
    index: 0,
    isCoverSlide: true
  };

  const photobooksWithCoverSpread = photobooks.map((photobook: any) => ({
    ...photobook,
    spreads: [coverSpread, ...photobook.spreads].map((spread, index) => ({ ...spread, index }))
  }));

  return { photobooksWithCoverSpread };
};
