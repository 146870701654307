import styled from 'styled-components';
import { displayProductPrice } from 'modules/ScrShop/store/utils';
import { Currency } from 'dinero.js';
import { theme } from 'assets/css/theme';

const Wrapper = styled.div<{ isStrike?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.primary};
  background: ${theme.colors.buttonSecondaryBg};
  padding: 2px 5px;
  border-radius: 30px;
  font-size: 12px;
  flex-direction: column;
  position: relative;

  span {
    font-size: 10px;
    text-decoration: ${({ isStrike }) => (isStrike ? 'line-through' : 'none')};
    position: absolute;
    top: -9px;
    right: -4px;
    color: #f00;
  }
`;

const PriceLabel = styled.div`
  font-size: 12px;
  margin-right: 5px;
`;

interface IProps {
  price: number;
  discountPrice: number;
  currency: Currency;
  priceLabel?: string;
}

export const PriceDisplayManager: React.FC<IProps> = ({
  price,
  discountPrice,
  currency = 'EUR',
  priceLabel
}) => {
  const finalPrice = discountPrice || price || 0;

  return (
    <Wrapper isStrike className="price-display-manager">
      {discountPrice ? <span>{displayProductPrice(price, currency)}</span> : null}
      <div className="d-flex">
        {priceLabel && <PriceLabel>{priceLabel}</PriceLabel>}
        <div className="text-underline">{displayProductPrice(finalPrice, currency)}</div>
      </div>
    </Wrapper>
  );
};
