import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelection } from 'old-store/types/gallery';

export interface IEndCustomerState {
  authed: boolean;
  preAuthed: boolean;
  _country: null | string;
  language: null | string;
  id: string;
  _id: string;
  _user: string;
  type: string; // fly...
  email: null | string;
  marketingEmailsEnabled: null | boolean;
  createdAt: string;
  updatedAt: string;
  firstName: null | string;
  lastName: null | string;
  telephoneNumber: null | string;
  name: null | string;
  password: null | string;
  endCustomerNumber: string;
  street: null | string;
  streetNumber: null | string;
  zipCode: null | string;
  city: null | string;
  sevDeskId: null | string;
  selectionName: string;
  message?: string;
  showPasswordChangeSuccess?: boolean;
  showPasswordChangeFailure?: boolean;
  selections: ISelection[];
  hiddenImages: string[];
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  selectionName: string;
  message?: string;
}

interface IPasswordRequest {
  showPasswordChangeSuccess?: boolean;
  showPasswordChangeFailure?: boolean;
}

interface ILoginRequest {
  authed: boolean;
}

interface IHiddenImages {
  hiddenImages: string[];
}

interface ISelections {
  selections: ISelection[];
}

type IActionType =
  | IEndCustomerState
  | IFormData
  | IPasswordRequest
  | ILoginRequest
  | IHiddenImages
  | ISelections;

const initialState: IEndCustomerState = {
  authed: false,
  preAuthed: false,
  _country: null,
  language: null,
  id: '',
  _id: '',
  _user: '',
  type: '',
  email: null,
  marketingEmailsEnabled: null,
  createdAt: '',
  updatedAt: '',
  firstName: null,
  lastName: null,
  telephoneNumber: null,
  name: null,
  password: null,
  endCustomerNumber: '',
  street: null,
  streetNumber: null,
  zipCode: null,
  city: null,
  sevDeskId: null,
  selectionName: '',
  message: '',
  showPasswordChangeSuccess: false,
  showPasswordChangeFailure: false,
  selections: [],
  hiddenImages: []
};

const endCustomerSlice = createSlice({
  name: 'endCustomer',
  initialState,
  reducers: {
    saveEndCustomer(state, { payload }: PayloadAction<IActionType>) {
      Object.assign(state, payload);
    }
  }
});

export const { saveEndCustomer } = endCustomerSlice.actions;

export const endCustomerReducer = endCustomerSlice.reducer;
